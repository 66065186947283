// src/components/StartNewDonation.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  FaUpload, FaFileAlt, FaTrash, FaSpinner, FaBuilding, 
  FaClipboard, FaCalendarAlt, FaDollarSign, FaPallet, 
  FaBalanceScale, FaClock, FaBoxOpen, FaFileInvoice, 
  FaListAlt, FaPlus, FaMinus, FaWeight 
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';
import { messageService } from '../services/messageService';

import '../styles/StartNewDonation.css';

const UNITS_OF_MEASURE = [
  'Cases',
  'Pounds',
  'Each',
  'Boxes',
  'Bags',
  'Bundles',
  'Drums',
  'Gallons',
  'Other'
];

const formatCurrency = (amount) => {
  if (!amount) return '';
  const numericValue = typeof amount === 'string' ? parseFloat(amount.replace(/[$,]/g, '')) : amount;
  if (isNaN(numericValue)) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numericValue);
};

const formatNumber = (value) => {
  if (!value) return '';
  const numericValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
  if (isNaN(numericValue)) return '';
  return new Intl.NumberFormat('en-US').format(numericValue);
};

const stripFormatting = (value) => {
  if (!value) return '';
  return value.toString().replace(/[$,]/g, '');
};

const validateRequired = (value, fieldName, rowIndex) => {
  if (value === undefined || value === '') {
    return `Row ${rowIndex}: Missing ${fieldName}`;
  }
  return null;
};

const validateNumber = (value, fieldName, rowIndex, allowZero = false) => {
  const num = parseFloat(stripFormatting(value));
  if (isNaN(num) || (!allowZero && num <= 0) || (allowZero && num < 0)) {
    return `Row ${rowIndex}: Invalid ${fieldName}`;
  }
  return null;
};

const validateDate = (value, rowIndex) => {
  if (value && isNaN(Date.parse(value))) {
    return `Row ${rowIndex}: Invalid date format`;
  }
  return null;
};

function DonationForm({ handleSubmit, handleFileUpload, fileName, handleRemoveFile, csvData, notes, setNotes, isLoading, distributionCenters, selectedDC, handleDCChange, donationMode, setDonationMode }) {
  return (
    <div className="start-new-donation-form">
      <h2><FaFileInvoice /> New Donation Details</h2>
      <div className="form-group">
        <label htmlFor="donation-mode">
          <FaListAlt /> Donation Mode
        </label>
        <select
          id="donation-mode"
          value={donationMode}
          onChange={(e) => setDonationMode(e.target.value)}
          required
          aria-required="true"
        >
          <option value="automatic">Automatic (CSV Upload)</option>
          <option value="manual">Manual Entry</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="distribution-center">
          <FaBuilding /> Distribution Center
        </label>
        <select
          id="distribution-center"
          value={selectedDC}
          onChange={handleDCChange}
          required
          aria-required="true"
        >
          <option value="">Select a Distribution Center</option>
          {distributionCenters.map(dc => (
            <option key={dc.id} value={dc.id}>{dc.name}</option>
          ))}
        </select>
      </div>
      {donationMode === 'automatic' ? (
        <AutomaticDonationForm
          handleFileUpload={handleFileUpload}
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
        />
      ) : (
        <ManualDonationForm handleSubmit={handleSubmit} />
      )}
      <div className="form-group">
        <label htmlFor="notes">
          <FaClipboard /> Additional Notes
          <span className="optional-indicator">(Optional)</span>
        </label>
        <textarea
          id="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows="4"
          placeholder="Enter any additional notes here..."
          aria-required="false"
        />
      </div>
      <button 
        type="submit" 
        onClick={(e) => handleSubmit(e, donationMode === 'manual' ? ManualDonationForm.getItems() : null)}
        disabled={isLoading || (donationMode === 'automatic' && csvData.length === 0) || !selectedDC}
        className="submit-button"
      >
        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Submitting...
          </>
        ) : (
          <>
            <FaBoxOpen /> Submit Donation
          </>
        )}
      </button>
    </div>
  );
}

function AutomaticDonationForm({ handleFileUpload, fileName, handleRemoveFile }) {
  return (
    <div className="form-group file-upload">
      <label htmlFor="csv-upload">
        <FaUpload /> Upload Inventory CSV
      </label>
      <div className="file-upload-help">
        <small>
          Required fields: SKU, Description, Quantity, Unit of Measure, Estimated Value, Pallet Count, Cost Basis
          <br />
          Optional fields: Expiration Date, Total Weight
        </small>
      </div>
      <input
        type="file"
        id="csv-upload"
        accept=".csv"
        onChange={handleFileUpload}
        required
        aria-required="true"
      />
      {fileName && (
        <div className="file-info">
          <FaFileAlt /> {fileName}
          <button type="button" onClick={handleRemoveFile} className="remove-file" aria-label="Remove file">
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
}
function ManualDonationForm({ handleSubmit }) {
  const initialItemState = {
    sku: '',
    description: '',
    quantity: '',
    unitOfMeasure: '',
    estimatedValue: '',
    palletCount: '',
    expirationDate: '',
    totalWeight: '',
    costBasis: ''
  };

  const [items, setItems] = useState([initialItemState]);
  const [focusedField, setFocusedField] = useState(null);

  const resetForm = () => {
    setItems([initialItemState]);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    
    if (['estimatedValue', 'costBasis'].includes(field)) {
      // Only allow numbers and decimal point while typing
      const strippedValue = value.replace(/[^\d.]/g, '');
      // Prevent multiple decimal points
      const decimalCount = (strippedValue.match(/\./g) || []).length;
      if (decimalCount <= 1) {
        // Allow up to 2 decimal places
        const parts = strippedValue.split('.');
        if (parts[1] && parts[1].length > 2) {
          parts[1] = parts[1].slice(0, 2);
          newItems[index][field] = parts.join('.');
        } else {
          newItems[index][field] = strippedValue;
        }
      }
    } else if (['quantity', 'palletCount', 'totalWeight'].includes(field)) {
      const strippedValue = stripFormatting(value);
      if (strippedValue === '' || (!isNaN(strippedValue) && parseFloat(strippedValue) >= 0)) {
        newItems[index][field] = strippedValue;
      }
    } else {
      newItems[index][field] = value;
    }
    
    setItems(newItems);
  };

  const getFormattedValue = (value, field, index) => {
    if (!value) return '';
    
    if (['estimatedValue', 'costBasis'].includes(field)) {
      // Only format with full currency when not focused
      if (focusedField === `${field}-${index}`) {
        return value; // Show raw value while typing
      }
      return formatCurrency(value);
    }
    if (['quantity', 'palletCount', 'totalWeight'].includes(field)) {
      return formatNumber(value);
    }
    return value;
  };

  const addItem = () => {
    setItems([...items, initialItemState]);
  };

  const removeItem = (index) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  ManualDonationForm.getItems = () => {
    return items.map(item => ({
      ...item,
      quantity: stripFormatting(item.quantity),
      estimatedValue: stripFormatting(item.estimatedValue),
      palletCount: stripFormatting(item.palletCount),
      totalWeight: stripFormatting(item.totalWeight),
      costBasis: stripFormatting(item.costBasis)
    }));
  };

  ManualDonationForm.resetForm = resetForm;

  return (
    <form onSubmit={handleSubmit} className="manual-donation-form">
      {items.map((item, index) => (
        <div key={index} className="item-form">
          <h3>Item {index + 1}</h3>
          
          <div className="form-group">
            <label htmlFor={`sku-${index}`}>
              <FaBoxOpen /> SKU
            </label>
            <input
              id={`sku-${index}`}
              type="text"
              value={item.sku}
              onChange={(e) => handleItemChange(index, 'sku', e.target.value)}
              className="text-input"
              required
              aria-required="true"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`description-${index}`}>
              <FaFileAlt /> Description
            </label>
            <input
              id={`description-${index}`}
              type="text"
              value={item.description}
              onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              className="text-input"
              required
              aria-required="true"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`quantity-${index}`}>
              <FaListAlt /> Quantity
            </label>
            <input
              id={`quantity-${index}`}
              type="text"
              value={getFormattedValue(item.quantity, 'quantity', index)}
              onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
              className="number-input"
              required
              aria-required="true"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`unitOfMeasure-${index}`}>
              <FaBalanceScale /> Unit of Measure
            </label>
            <select
              id={`unitOfMeasure-${index}`}
              value={item.unitOfMeasure}
              onChange={(e) => handleItemChange(index, 'unitOfMeasure', e.target.value)}
              className="select-input"
              required
              aria-required="true"
            >
              <option value="">Select Unit</option>
              {UNITS_OF_MEASURE.map(unit => (
                <option key={unit} value={unit}>{unit}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor={`estimatedValue-${index}`}>
              <FaDollarSign /> Estimated Value
            </label>
            <input
              id={`estimatedValue-${index}`}
              type="text"
              value={getFormattedValue(item.estimatedValue, 'estimatedValue', index)}
              onChange={(e) => handleItemChange(index, 'estimatedValue', e.target.value)}
              onFocus={() => setFocusedField(`estimatedValue-${index}`)}
              onBlur={() => setFocusedField(null)}
              className="currency-input"
              required
              aria-required="true"
              placeholder="0.00"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`palletCount-${index}`}>
              <FaPallet /> Pallet Count
            </label>
            <input
              id={`palletCount-${index}`}
              type="text"
              value={getFormattedValue(item.palletCount, 'palletCount', index)}
              onChange={(e) => handleItemChange(index, 'palletCount', e.target.value)}
              className="number-input"
              required
              aria-required="true"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`expirationDate-${index}`}>
              <FaCalendarAlt /> Expiration Date
              <span className="optional-indicator">(Optional)</span>
            </label>
            <input
              id={`expirationDate-${index}`}
              type="date"
              value={item.expirationDate}
              onChange={(e) => handleItemChange(index, 'expirationDate', e.target.value)}
              className="date-input"
              aria-required="false"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`totalWeight-${index}`}>
              <FaWeight /> Total Weight (lbs)
              <span className="optional-indicator">(Optional)</span>
            </label>
            <input
              id={`totalWeight-${index}`}
              type="text"
              value={getFormattedValue(item.totalWeight, 'totalWeight', index)}
              onChange={(e) => handleItemChange(index, 'totalWeight', e.target.value)}
              className="number-input"
              aria-required="false"
            />
          </div>

          <div className="form-group">
            <label htmlFor={`costBasis-${index}`}>
              <FaDollarSign /> Cost Basis
            </label>
            <input
              id={`costBasis-${index}`}
              type="text"
              value={getFormattedValue(item.costBasis, 'costBasis', index)}
              onChange={(e) => handleItemChange(index, 'costBasis', e.target.value)}
              onFocus={() => setFocusedField(`costBasis-${index}`)}
              onBlur={() => setFocusedField(null)}
              className="currency-input"
              required
              aria-required="true"
              placeholder="0.00"
            />
          </div>

          {items.length > 1 && (
            <button type="button" onClick={() => removeItem(index)} className="remove-item">
              <FaMinus /> Remove Item
            </button>
          )}
        </div>
      ))}
      <button type="button" onClick={addItem} className="add-item">
        <FaPlus /> Add Item
      </button>
    </form>
  );
}
function CsvPreview({ csvData }) {
  if (csvData.length === 0) return null;

  return (
    <div className="csv-preview">
      <h3><FaListAlt /> CSV Preview</h3>
      <div className="csv-table-container">
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Value</th>
              <th>Pallets</th>
              <th>Weight</th>
              <th>Expiration Date</th>
              <th>Cost Basis</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                <td>{row.sku}</td>
                <td>{row.description}</td>
                <td>{formatNumber(row.quantity)}</td>
                <td>{row.unitofmeasure}</td>
                <td>{formatCurrency(row.estimatedvalue)}</td>
                <td>{formatNumber(row.palletcount)}</td>
                <td>{row.totalweight ? `${formatNumber(row.totalweight)} lbs` : 'N/A'}</td>
                <td>{row.expirationdate ? new Date(row.expirationdate).toLocaleDateString() : 'N/A'}</td>
                <td>{formatCurrency(row.costbasis)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="preview-note">Showing all {csvData.length} items</p>
    </div>
  );
}

function PendingDonations({ pendingDonations }) {
  return (
    <div className="pending-donations">
      <h3><FaClock /> Pending Donations</h3>
      {pendingDonations.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th><FaCalendarAlt /> Date</th>
                <th><FaDollarSign /> Total Value</th>
                <th><FaPallet /> Total Pallets</th>
                <th><FaWeight /> Total Weight</th>
                <th><FaBalanceScale /> Total Cost Basis</th>
                <th><FaClock /> Status</th>
              </tr>
            </thead>
            <tbody>
              {pendingDonations.map((donation) => (
                <tr key={donation.id}>
                  <td>{donation.createdAt ? new Date(donation.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}</td>
                  <td>{formatCurrency(donation.totalEstimatedValue)}</td>
                  <td>{formatNumber(donation.totalPalletCount)}</td>
                  <td>{donation.totalWeight ? `${formatNumber(donation.totalWeight)} lbs` : 'N/A'}</td>
                  <td>{formatCurrency(donation.totalCostBasis)}</td>
                  <td>{donation.status || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-donations">No pending donations</p>
      )}
    </div>
  );
}

function StartNewDonation() {
  const [user, setUser] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [pendingDonations, setPendingDonations] = useState([]);
  const [selectedDC, setSelectedDC] = useState('');
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [donationMode, setDonationMode] = useState('manual');

  const fetchPendingDonations = useCallback(async (distributionCenterId) => {
    try {
      const donations = await donationService.getByDistributionCenter(distributionCenterId);
      const pending = donations.filter(donation => 
        donation.status && donation.status.toLowerCase() === 'pending'
      );
      setPendingDonations(pending);
    } catch (error) {
      console.error('Error fetching pending donations:', error);
      toast.error('Failed to fetch pending donations. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    const fetchUserAndDonations = async () => {
      try {
        const currentUser = authService.getUserSession();
        if (currentUser) {
          const fullUserData = await userService.getById(currentUser.id);
          setUser(fullUserData);

          const userDCs = fullUserData.distributionCenters || [fullUserData.distributionCenter];

          if (userDCs.length > 0) {
            const dcs = await Promise.all(userDCs.map(dcId => 
              distributionCenterService.getById(dcId)
            ));
            setDistributionCenters(dcs);
            setSelectedDC(userDCs[0]);
            await fetchPendingDonations(userDCs[0]);
          } else {
            toast.error('No distribution centers assigned. Please contact support.');
          }
        } else {
          toast.error('Please log in to view this page.');
        }
      } catch (error) {
        console.error('Error in fetchUserAndDonations:', error);
        toast.error('An error occurred while fetching user data. Please try again.');
      }
    };
    fetchUserAndDonations();
  }, [fetchPendingDonations]);

  const handleDCChange = async (e) => {
    const newSelectedDC = e.target.value;
    setSelectedDC(newSelectedDC);
    await fetchPendingDonations(newSelectedDC);
  };

  const mapItemToSubmission = (item, isCSV = true) => ({
    itemID: uuidv4(),
    sku: isCSV ? item.sku : item.sku,
    description: isCSV ? item.description : item.description,
    quantity: parseFloat(stripFormatting(isCSV ? item.quantity : item.quantity)),
    unitOfMeasure: isCSV ? item.unitofmeasure : item.unitOfMeasure,
    estimatedValue: parseFloat(stripFormatting(isCSV ? item.estimatedvalue : item.estimatedValue)),
    palletCount: parseInt(stripFormatting(isCSV ? item.palletcount : item.palletCount)),
    expirationDate: (isCSV ? item.expirationdate : item.expirationDate) 
      ? new Date(isCSV ? item.expirationdate : item.expirationDate) 
      : null,
    totalWeight: (isCSV ? item.totalweight : item.totalWeight) 
      ? parseFloat(stripFormatting(isCSV ? item.totalweight : item.totalWeight))
      : null,
    costBasis: parseFloat(stripFormatting(isCSV ? item.costbasis : item.costBasis)),
    status: 'Pending'
  });

  const handleSubmit = async (e, manualItems = null) => {
    e.preventDefault();
    if (donationMode === 'automatic' && csvData.length === 0) {
      toast.error('Please upload a valid CSV file');
      return;
    }
  
    if (!selectedDC) {
      toast.error('Please select a distribution center');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const donationItems = donationMode === 'automatic'
        ? csvData.map(item => mapItemToSubmission(item, true))
        : manualItems.map(item => mapItemToSubmission(item, false));
  
      const donationData = {
        companyId: user.company,
        distributionCenterId: selectedDC,
        createdBy: user.id,
        status: 'Pending',
        items: donationItems,
        totalEstimatedValue: donationItems.reduce((sum, item) => sum + item.estimatedValue, 0),
        totalPalletCount: donationItems.reduce((sum, item) => sum + item.palletCount, 0),
        totalCostBasis: donationItems.reduce((sum, item) => sum + item.costBasis, 0),
        totalWeight: donationItems.reduce((sum, item) => sum + (item.totalWeight || 0), 0),
        notes: notes || null
      };
  
      // Create the donation
      const newDonationId = await donationService.create(donationData);
      
      // Get DC details for the notification
      const dc = distributionCenters.find(dc => dc.id === selectedDC);
      
      // Send notification
      if (user.phone) {
        try {
          await messageService.sendDonationCreatedNotification(user.phone, {
            ...donationData,
            id: newDonationId,
            distributionCenterName: dc.name
          });
        } catch (messageError) {
          console.error('Error sending notification:', messageError);
          // Don't throw here - we still want to show success for the donation
        }
      }
  
      toast.success('Donation submitted successfully!');
      
      if (donationMode === 'automatic') {
        setCsvData([]);
        setFileName('');
      } else {
        ManualDonationForm.resetForm();
      }
      
      setNotes('');
      await fetchPendingDonations(selectedDC);
    } catch (error) {
      console.error('Error creating donation:', error);
      toast.error('Failed to create donation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setCsvData([]);

    Papa.parse(file, {
      complete: (result) => {
        if (result.data && result.data.length > 1) {
          const headers = result.data[0].map(header => header.toLowerCase());
          const parsedData = result.data.slice(1)
            .filter(row => row.length === headers.length && row.some(cell => cell !== ''))
            .map(row => {
              const item = {};
              headers.forEach((header, index) => {
                item[header] = row[index];
              });
              return item;
            });

          const validationErrors = validateCsvData(parsedData);
          if (validationErrors.length > 0) {
            toast.error(validationErrors.join('\n'));
          } else {
            setCsvData(parsedData);
          }
        } else {
          toast.error('The CSV file is empty or invalid');
        }
      },
      header: false,
      error: (error) => {
        toast.error(`Error parsing CSV: ${error.message}`);
      }
    });
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName('');
  };

  if (!user) return <Loader />;

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="start-new-donation-container">
          <h1><FaBoxOpen /> Start New Donation</h1>
          <DonationForm 
            handleSubmit={handleSubmit}
            handleFileUpload={handleFileUpload}
            fileName={fileName}
            handleRemoveFile={handleRemoveFile}
            csvData={csvData}
            notes={notes}
            setNotes={setNotes}
            isLoading={isLoading}
            distributionCenters={distributionCenters}
            selectedDC={selectedDC}
            handleDCChange={handleDCChange}
            donationMode={donationMode}
            setDonationMode={setDonationMode}
          />
          {csvData.length > 0 && donationMode === 'automatic' && <CsvPreview csvData={csvData} />}
          <PendingDonations pendingDonations={pendingDonations} />
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
}

const validateCsvData = (data) => {
  const requiredFields = ['sku', 'description', 'quantity', 'unitofmeasure', 'estimatedvalue', 'palletcount', 'costbasis'];
  const errors = [];

  data.forEach((row, index) => {
    requiredFields.forEach(field => {
      const error = validateRequired(row[field], field, index + 2);
      if (error) errors.push(error);
    });

    const numberValidations = [
      { value: row.quantity, field: 'quantity', allowZero: false },
      { value: row.estimatedvalue, field: 'estimated value', allowZero: false },
      { value: row.palletcount, field: 'pallet count', allowZero: true },
      { value: row.costbasis, field: 'cost basis', allowZero: true }
    ];

    numberValidations.forEach(({ value, field, allowZero }) => {
      const error = validateNumber(value, field, index + 2, allowZero);
      if (error) errors.push(error);
    });

    if (row.expirationdate && row.expirationdate.trim() !== '') {
      const dateError = validateDate(row.expirationdate, index + 2);
      if (dateError) errors.push(dateError);
    }

    if (row.totalweight && row.totalweight.trim() !== '') {
      const weightError = validateNumber(row.totalweight, 'total weight', index + 2, true);
      if (weightError) errors.push(weightError);
    }
  });

  return errors;
};

export default StartNewDonation;