// src/components/ClientPreviewRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { authService } from '../services/authService';
import { clientOrganizationService } from '../services/clientOrganizationService';

const ClientPreviewRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { orgId } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await authService.getCurrentUser();
      setUser(currentUser);
      setLoading(false);
    };
    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/client-login" />;
  }

  // Get organization info
  const organization = clientOrganizationService.getOrganizationForEmail(user.email);
  
  // Check if user is authorized for this specific organization
  if (!organization || organization.previewPath !== `/client-preview/${orgId}`) {
    return <Navigate to="/client-login" />;
  }

  // Render the organization-specific presentation
  const PresentationComponent = organization.presentationComponent;
  return <PresentationComponent />;
};

export default ClientPreviewRoute;