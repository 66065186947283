// CharityCard.js
import React, { useMemo } from 'react';
import { 
  FaMapMarkerAlt, 
  FaExternalLinkAlt, 
  FaWarehouse, 
  FaTruck,
  FaCalendarCheck,
  FaChevronRight,
  FaClock
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../../styles/Charities/CharityCard.css';

const CharityCard = ({ charity, onViewDetails }) => {
  const mainLocation = useMemo(() => (
    charity.locations.find(loc => loc.isMainLocation) || charity.locations[0]
  ), [charity.locations]);

  const totalPalletCapacity = useMemo(() => 
    charity.locations.reduce((sum, loc) => 
      sum + (loc.palletCapacity.maximum || 0), 0
    ), [charity.locations]
  );

  const isCurrentlyOpen = useMemo(() => {
    if (!mainLocation?.hours) return false;
    
    const now = new Date();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const day = days[now.getDay()];
    const currentTime = now.toLocaleTimeString('en-US', { 
      hour12: false, 
      hour: '2-digit', 
      minute: '2-digit' 
    });
    
    const hours = mainLocation.hours[day];
    if (!hours || hours.toLowerCase() === 'closed') return false;
    
    const [open, close] = hours.split('-');
    return currentTime >= open && currentTime <= close;
  }, [mainLocation]);

  const formatAddress = (address) => {
    return `${address.city}, ${address.stateProvince}`;
  };

  const handleCardClick = () => {
    onViewDetails(charity.id);
  };

  const handleWebsiteClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div 
      className="charity-card"
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleCardClick();
      }}
    >
      {/* Logo Section */}
      <div className="charity-card-logo">
        {charity.logoUrl ? (
          <img 
            src={charity.logoUrl} 
            alt={`${charity.orgName} logo`} 
            className="charity-logo"
          />
        ) : (
          <div className="charity-logo-placeholder">
            {charity.orgName.charAt(0)}
          </div>
        )}
      </div>

      {/* Title Section */}
      <div className="charity-title-section">
        <div className="charity-header">
          <h3 className="charity-name">{charity.orgName}</h3>
          {charity.orgWebsite && (
            <a 
              href={charity.orgWebsite}
              target="_blank"
              rel="noopener noreferrer"
              className="website-link"
              onClick={handleWebsiteClick}
              aria-label={`Visit ${charity.orgName}'s website`}
            >
              <FaExternalLinkAlt />
            </a>
          )}
        </div>
        
        <div className="charity-causes">
          {charity.organizationCauses.slice(0, 3).map((cause, index) => (
            <span key={index} className="cause-tag" title={cause}>
              {cause}
            </span>
          ))}
          {charity.organizationCauses.length > 3 && (
            <span className="more-tag" title="More causes available">
              +{charity.organizationCauses.length - 3}
            </span>
          )}
        </div>
      </div>

      {/* Content Section */}
      <div className="charity-card-content">
        <p className="charity-description">
          {charity.description}
        </p>

        <div className="charity-info-section">
          <div className="info-item" title="Locations">
            <FaMapMarkerAlt className="info-icon" />
            <span>
              {charity.locations.length} location{charity.locations.length !== 1 ? 's' : ''}
              {mainLocation && ` · ${formatAddress(mainLocation.address)}`}
            </span>
          </div>

          <div className="info-item" title="Total Pallet Capacity">
            <FaWarehouse className="info-icon" />
            <span>{totalPalletCapacity} pallet capacity</span>
          </div>

          {mainLocation && (
            <>
              {mainLocation.facilities.hasLoadingDock && (
                <div className="info-item" title="Loading Dock Available">
                  <FaTruck className="info-icon" />
                  <span>Loading dock available</span>
                </div>
              )}

              <div className="info-item" title="Operating Hours">
                <FaClock className="info-icon" />
                <span>{isCurrentlyOpen ? 'Currently Open' : 'Currently Closed'}</span>
              </div>

              {mainLocation.appointmentRequired && (
                <div className="info-item" title="Appointment Required">
                  <FaCalendarCheck className="info-icon" />
                  <span>Appointment required</span>
                </div>
              )}
            </>
          )}
        </div>

        <div className="charity-goods-section">
          <h4 className="section-title">Acceptable Goods</h4>
          <div className="goods-tags">
            {charity.acceptableGoods.slice(0, 5).map((good, index) => (
              <span key={index} className="good-tag" title={good}>
                {good}
              </span>
            ))}
            {charity.acceptableGoods.length > 5 && (
              <span 
                className="more-tag"
                title={`${charity.acceptableGoods.length - 5} more goods accepted`}
              >
                +{charity.acceptableGoods.length - 5} more
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="charity-card-footer">
        <button 
          className="view-details-button"
          onClick={handleCardClick}
          aria-label={`View details for ${charity.orgName}`}
        >
          View Details
          <FaChevronRight className="chevron-icon" />
        </button>
      </div>
    </div>
  );
};

CharityCard.propTypes = {
  charity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    orgWebsite: PropTypes.string,
    organizationCauses: PropTypes.arrayOf(PropTypes.string).isRequired,
    acceptableGoods: PropTypes.arrayOf(PropTypes.string).isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMainLocation: PropTypes.bool,
      address: PropTypes.shape({
        city: PropTypes.string.isRequired,
        stateProvince: PropTypes.string.isRequired
      }).isRequired,
      palletCapacity: PropTypes.shape({
        maximum: PropTypes.number
      }).isRequired,
      facilities: PropTypes.shape({
        hasLoadingDock: PropTypes.bool
      }).isRequired,
      hours: PropTypes.object,
      appointmentRequired: PropTypes.bool
    })).isRequired
  }).isRequired,
  onViewDetails: PropTypes.func.isRequired
};

export default React.memo(CharityCard);