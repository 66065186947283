// src/services/donationService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const COLLECTION_NAME = 'donations';
const storage = getStorage();

export const donationService = {
  async create(donationData) {
    const newDonationRef = doc(collection(db, COLLECTION_NAME));
    await setDoc(newDonationRef, { ...donationData, createdAt: new Date(), updatedAt: new Date() });
    return newDonationRef.id;
  },

  async getById(donationId) {
    const donationDoc = await getDoc(doc(db, COLLECTION_NAME, donationId));
    return donationDoc.exists() ? { id: donationDoc.id, ...donationDoc.data() } : null;
  },

  async getByCompany(companyId) {
    console.log('Fetching donations for company:', companyId);
    const q = query(collection(db, COLLECTION_NAME), where("companyId", "==", companyId));
    const querySnapshot = await getDocs(q);
    const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('Fetched donations:', donations);
    return donations;
  },

  async getByDistributionCenter(dcId) {
    console.log('Fetching donations for distribution center:', dcId);
    const q = query(collection(db, COLLECTION_NAME), where("distributionCenterId", "==", dcId));
    const querySnapshot = await getDocs(q);
    const donations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log('Fetched donations:', donations);
    return donations;
  },

  async update(donationId, updateData) {
    await updateDoc(doc(db, COLLECTION_NAME, donationId), { ...updateData, updatedAt: new Date() });
  },

  async delete(donationId) {
    await deleteDoc(doc(db, COLLECTION_NAME, donationId));
  },

  async updateItem(donationId, itemId, updateData) {
    try {
      console.log(`Updating item ${itemId} in donation ${donationId} with data:`, updateData);
      
      if (!donationId) {
        throw new Error('Donation ID is missing');
      }
  
      const donationRef = doc(db, 'donations', donationId);
      const donationDoc = await getDoc(donationRef);
  
      if (!donationDoc.exists()) {
        throw new Error('Donation not found');
      }
  
      const donationData = donationDoc.data();
      const updatedItems = donationData.items.map(item => 
        item.itemID === itemId ? { ...item, ...updateData } : item
      );
  
      const updatedDonationData = {
        ...donationData,
        items: updatedItems,
        updatedAt: new Date()
      };
  
      await updateDoc(donationRef, updatedDonationData);
  
      // Return the updated data instead of mixing old data with new items
      return { id: donationId, ...updatedDonationData };
    } catch (error) {
      console.error('Error updating item:', error);
      throw error;
    }
  },

  async submitFMVAssessment(donationId, itemId, fairMarketValue, company, distributionCenter) {
    try {
      const donation = await this.getById(donationId);
      const item = donation.items.find(i => i.itemID === itemId);
  
      const pdfBlob = await this.generateFMVAssessmentPDF(item, fairMarketValue, company, distributionCenter);
      const pdfUrl = await this.uploadFMVAssessmentPDF(pdfBlob, donationId, itemId);
  
      // Include status update in the Firestore update
      const updatedDonation = await this.updateItem(donationId, itemId, {
        fairMarketValue: parseFloat(fairMarketValue),
        fmvAssessmentUrl: pdfUrl,
        status: 'Valued'  // Add this line to persist the status change
      });
  
      return updatedDonation;
    } catch (error) {
      console.error('Error submitting FMV Assessment:', error);
      throw error;
    }
  },

  async generateFMVAssessmentPDF(item, fairMarketValue, company, distributionCenter) {
    const pdf = new jsPDF();

    pdf.setFont("helvetica");
    pdf.setFontSize(22);
    pdf.setTextColor(44, 62, 80);
    pdf.text('Fair Market Value Assessment', pdf.internal.pageSize.width / 2, 20, { align: 'center' });

    pdf.setFontSize(12);
    pdf.setTextColor(44, 62, 80);
    pdf.text('Company:', 10, 40);
    pdf.setFontSize(10);
    pdf.setTextColor(52, 73, 94);
    pdf.text(company.name, 50, 40);

    pdf.setFontSize(12);
    pdf.setTextColor(44, 62, 80);
    pdf.text('Distribution Center:', 10, 50);
    pdf.setFontSize(10);
    pdf.setTextColor(52, 73, 94);
    pdf.text(distributionCenter.name, 50, 50);

    pdf.setFontSize(12);
    pdf.setTextColor(44, 62, 80);
    pdf.text('Assessment Date:', 10, 60);
    pdf.setFontSize(10);
    pdf.setTextColor(52, 73, 94);
    pdf.text(new Date().toLocaleDateString(), 50, 60);

    pdf.autoTable({
      startY: 70,
      head: [['Item Description', 'Quantity', 'Pallet Count', 'Fair Market Value']],
      body: [
        [
          item.description,
          item.quantity.toString(),
          item.palletCount.toString(),
          `$${parseFloat(fairMarketValue).toFixed(2)}`
        ]
      ],
      theme: 'striped',
      headStyles: { fillColor: [41, 128, 185], textColor: 255 },
      alternateRowStyles: { fillColor: [236, 240, 241] },
    });

    let yPos = pdf.autoTable.previous.finalY + 20;

    pdf.setFontSize(12);
    pdf.setTextColor(44, 62, 80);
    pdf.text('Assessment Notes:', 10, yPos);
    yPos += 10;
    pdf.setFontSize(10);
    pdf.setTextColor(52, 73, 94);
    pdf.text('This Fair Market Value (FMV) assessment is based on current market conditions and the item\'s condition.', 10, yPos, { maxWidth: 180 });

    pdf.setFontSize(8);
    pdf.setTextColor(127, 140, 141);
    pdf.text('This is a computer-generated document. No signature is required.', 10, pdf.internal.pageSize.height - 10);

    return pdf.output('blob');
  },

  async uploadFMVAssessmentPDF(pdfBlob, donationId, itemId) {
    try {
      const storageReference = ref(storage, `fmvAssessments/${donationId}/${itemId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading FMV Assessment PDF:', error);
      throw error;
    }
  },

  async assignItemToCharities(donationId, itemId, assignments) {
    try {
      const donationRef = doc(db, COLLECTION_NAME, donationId);
      const donationDoc = await getDoc(donationRef);
      
      if (!donationDoc.exists()) {
        throw new Error('Donation not found');
      }
    
      const donationData = donationDoc.data();
      const updatedItems = donationData.items.map(item => 
        item.itemID === itemId ? { ...item, charityAssignments: assignments } : item
      );
    
      await updateDoc(donationRef, { 
        items: updatedItems,
        updatedAt: new Date()
      });
    
      return { id: donationId, ...donationData, items: updatedItems };
    } catch (error) {
      console.error('Error assigning item to charities:', error);
      throw error;
    }
  },

};