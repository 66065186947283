// src/components/Reports.js


import React, { useReducer, useCallback, useMemo, useEffect, useState, useRef } from 'react';
import {
  FaBoxes, FaCalendarAlt, FaChartLine, FaChartPie, FaDollarSign,
  FaDownload, FaFileAlt, FaFileContract, FaFileCsv, FaFileInvoiceDollar,
  FaFilePdf, FaFilter, FaGlobe, FaHandHoldingHeart, FaMapMarkerAlt,
  FaMoneyBillWave, FaPallet, FaReceipt, FaSearch, FaSyncAlt, FaUndo,
  FaWarehouse, FaBuilding, FaBoxOpen, FaMapMarkedAlt, FaLayerGroup,
  FaHeart, FaPhoneAlt, FaEnvelope, FaExternalLinkAlt, FaUsers,
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  TimeScale
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'chartjs-adapter-date-fns';
import Header from './Header';
import Sidebar from './Sidebar';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import { completedDonationsService } from '../services/completedDonationsService';
import { distributionCenterService } from '../services/distributionCenterService';
import { charityService } from '../services/charityService';
import { companyService } from '../services/companyService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { reportsService, helpers } from '../services/reportsService';
import { mapService } from '../services/mapService';
import '../styles/Reports.css';


ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, ArcElement,
  Title, ChartTooltip, Legend, TimeScale
);


const initialState = {
  completedDonations: [],
  user: null,
  loading: true,
  error: null,
  filters: {
    distributionCenter: 'all',
    company: 'all',
    charity: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  },
  distributionCenters: [],
  companies: [],
  charities: {},
  sortConfig: { key: 'completionDate', direction: 'descending' },
  currentPage: 1,
  itemsPerPage: 10,
};

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};


const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};


const formatAbbreviatedCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return formatCurrency(value);
};


function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_FILTER':
      return { ...state, filters: { ...state.filters, [action.payload.name]: action.payload.value } };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters };
    case 'SET_SORT':
      return { ...state, sortConfig: action.payload };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    case 'SET_ITEMS_PER_PAGE':
      return { ...state, itemsPerPage: action.payload };
    default:
      return state;
  }
}

const mapCharityData = (charity) => {
  // Get the main contact from the first location's contacts array
  const mainLocation = charity.locations?.find(loc => loc.isMainLocation) || charity.locations?.[0];
  const mainContact = mainLocation?.contacts?.find(contact => contact.isMainContact) || mainLocation?.contacts?.[0];

  return {
    id: charity.id,
    name: charity.orgName,
    description: charity.description,
    logoUrl: charity.logoUrl,
    address: mainLocation?.address || {},
    contactInfo: mainContact ? {
      phone: mainContact.phoneNumber,
      email: mainContact.email,
      website: charity.orgWebsite
    } : {
      website: charity.orgWebsite
    },
    stats: {} // This will be populated by calculateCharityStats
  };
};

const calculateDistributionCenterStats = (donations, distributionCenterId) => {
  const dcDonations = donations.filter(d => d.distributionCenterId === distributionCenterId);
  return {
    totalDonations: dcDonations.length,
    totalFairMarketValue: dcDonations.reduce((sum, d) => sum + d.fairMarketValue, 0),
    charitiesServed: new Set(dcDonations.flatMap(d => d.palletGroups.map(pg => pg.charityId))).size
  };
};


const calculateCharityStats = (donations, charityId) => {
  const charityDonations = donations.filter(d =>
    d.palletGroups.some(pg => pg.charityId === charityId)
  );
  return {
    totalDonationsReceived: charityDonations.length,
    totalFairMarketValue: charityDonations.reduce((sum, d) => {
      const charityPallets = d.palletGroups.find(pg => pg.charityId === charityId).palletQuantity;
      return sum + (d.fairMarketValue * charityPallets / d.palletCount);
    }, 0),
    palletsReceived: charityDonations.reduce((sum, d) =>
      sum + d.palletGroups.find(pg => pg.charityId === charityId).palletQuantity, 0
    )
  };
};


const getFilteredLocations = (visibleDonations, allDistributionCenters, allCharities) => {
  const usedDCIds = new Set();
  const usedCharityIds = new Set();


  visibleDonations.forEach(donation => {
    usedDCIds.add(donation.distributionCenterId);
    donation.palletGroups.forEach(group => usedCharityIds.add(group.charityId));
  });


  const filteredDCs = allDistributionCenters.filter(dc => usedDCIds.has(dc.id));
  const filteredCharities = Object.values(allCharities).filter(charity => usedCharityIds.has(charity.id));


  return { filteredDCs, filteredCharities };
};


const Reports = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [charityImpactFilter, setCharityImpactFilter] = useState('all');
  const [userRole, setUserRole] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [userDistributionCenters, setUserDistributionCenters] = useState([]);


  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');
  
      const userData = await userService.getById(currentUser.id);
      const userRole = await userService.getUserRole(currentUser.id);
      setUserRole(userRole);
  
      let userDCs, companies, rawCharities, allDonations;
  
      if (userRole === 'SystemAdmin') {
        [userDCs, companies, rawCharities, allDonations] = await Promise.all([
          distributionCenterService.getAll(),
          companyService.getAll(),
          charityService.getAll(), // Using new charityService
          completedDonationsService.getAll()
        ]);
      } else {
        setUserCompany(userData.company);
        const userDCIds = userData.distributionCenters || [userData.distributionCenter];
        setUserDistributionCenters(userDCIds);
  
        if (userDCIds.length === 0) {
          throw new Error('No distribution centers assigned to user');
        }
  
        [userDCs, companies, rawCharities, allDonations] = await Promise.all([
          distributionCenterService.getAll(),
          [await companyService.getById(userData.company)],
          charityService.getAll(),
          Promise.all(userDCIds.map(dcId => 
            completedDonationsService.getByDistributionCenter(dcId)))
        ]);
  
        allDonations = allDonations.flat();
      }
  
      // Transform and combine the data
      const dcStats = userDCs.map(dc => ({
        ...dc,
        stats: calculateDistributionCenterStats(allDonations, dc.id)
      }));
  
      const charitiesMap = Object.fromEntries(
        rawCharities.map(charity => {
          const mappedCharity = mapCharityData(charity);
          mappedCharity.stats = calculateCharityStats(allDonations, charity.id);
          return [charity.id, mappedCharity];
        })
      );
  
      dispatch({
        type: 'SET_DATA',
        payload: {
          user: userData,
          distributionCenters: dcStats,
          companies: companies,
          completedDonations: allDonations,
          charities: charitiesMap
        }
      });
  
      if (userRole !== 'SystemAdmin') {
        dispatch({ type: 'SET_FILTER', payload: { name: 'company', value: userData.company } });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: 'SET_ERROR', payload: 'An error occurred while fetching data. Please try again.' });
      toast.error('Failed to load reports data. Please refresh the page.');
    }
  }, []);


  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const filteredAndSortedDonations = useMemo(() => {
    let result = state.completedDonations.filter(donation => {
      const searchLower = state.filters.searchTerm.toLowerCase();
      const matchesSearch =
        donation.id.toLowerCase().includes(searchLower) ||
        donation.itemId.toLowerCase().includes(searchLower) ||
        donation.companyId.toLowerCase().includes(searchLower);
     
      const matchesDistributionCenter = state.filters.distributionCenter === 'all' ||
        donation.distributionCenterId === state.filters.distributionCenter;


      const matchesCompany = state.filters.company === 'all' ||
        donation.companyId === state.filters.company;


      const matchesCharity = state.filters.charity === 'all' ||
        donation.palletGroups.some(group => group.charityId === state.filters.charity);
 
      const matchesDateRange =
        (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
        (new Date(donation.completionDate) >= new Date(state.filters.dateRange.start) &&
         new Date(donation.completionDate) <= new Date(state.filters.dateRange.end));
 
      return matchesSearch && matchesDistributionCenter && matchesCompany && matchesCharity && matchesDateRange;
    });


    if (state.sortConfig.key) {
      result.sort((a, b) => {
        if (a[state.sortConfig.key] < b[state.sortConfig.key]) {
          return state.sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[state.sortConfig.key] > b[state.sortConfig.key]) {
          return state.sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
 
    return result;
  }, [state.completedDonations, state.filters, state.sortConfig]);
 
  const paginatedDonations = useMemo(() => {
    const startIndex = (state.currentPage - 1) * state.itemsPerPage;
    return filteredAndSortedDonations.slice(startIndex, startIndex + state.itemsPerPage);
  }, [filteredAndSortedDonations, state.currentPage, state.itemsPerPage]);
 
  const summaryStats = useMemo(() => {
    return filteredAndSortedDonations.reduce((acc, donation) => {
      acc.totalDonations++;
      acc.totalFairMarketValue += donation.fairMarketValue;
      acc.totalEstTaxSavings += Math.min(donation.costBasis + (donation.fairMarketValue - donation.costBasis) / 2, 2 * donation.costBasis);
      acc.totalPallets += donation.palletCount;
      acc.totalQuantity += donation.quantity;
      return acc;
    }, {
      totalDonations: 0,
      totalFairMarketValue: 0,
      totalEstTaxSavings: 0,
      totalPallets: 0,
      totalQuantity: 0
    });
  }, [filteredAndSortedDonations]);
 
  const { filteredDCs, filteredCharities } = useMemo(() => {
    return getFilteredLocations(filteredAndSortedDonations, state.distributionCenters, state.charities);
  }, [filteredAndSortedDonations, state.distributionCenters, state.charities]);


  const getCumulativeDonationsData = () => {
    const sortedDonations = [...filteredAndSortedDonations].sort((a, b) => new Date(a.completionDate) - new Date(b.completionDate));
    let cumulativeValue = 0;
    const data = sortedDonations.reduce((acc, donation) => {
      cumulativeValue += donation.fairMarketValue;
      acc.push({
        x: new Date(donation.completionDate),
        y: cumulativeValue,
        donation: donation
      });
      return acc;
    }, []);
 
    return {
      labels: data.map(item => item.x),
      datasets: [{
        label: 'Cumulative Donations Value',
        data: data,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
        pointHoverBorderWidth: 2,
      }]
    };
  };
 
  const getCharityChartData = () => {
    const data = {};
    filteredAndSortedDonations.forEach(donation => {
      donation.palletGroups.forEach(group => {
        const charityName = state.charities[group.charityId]?.name || 'Unknown';
        data[charityName] = (data[charityName] || 0) + group.palletQuantity;
      });
    });
    return {
      labels: Object.keys(data),
      datasets: [{
        data: Object.values(data),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
        type: 'pie'
      }]
    };
  };
 
  const handleSort = (key) => {
    let direction = 'ascending';
    if (state.sortConfig.key === key && state.sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    dispatch({ type: 'SET_SORT', payload: { key, direction } });
  };
 
  const handleCSVExport = () => {
    const csvData = reportsService.generateCSV(filteredAndSortedDonations, state.charities, summaryStats);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'donations-report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
 
  const handlePDFExport = async () => {
    try {
      const chartData = {
        cumulativeData: getCumulativeDonationsData(),
        charityData: getCharityChartData()
      };
     
      const formattedSummaryStats = {
        ...summaryStats,
        totalDonations: summaryStats.totalDonations,
        totalFairMarketValue: summaryStats.totalFairMarketValue.toFixed(2),
        totalEstTaxSavings: summaryStats.totalEstTaxSavings.toFixed(2),
        totalPallets: summaryStats.totalPallets,
        totalQuantity: summaryStats.totalQuantity
      };
 
      const metadata = {
        userRole,
        companyName: userRole !== 'SystemAdmin' ? state.companies.find(c => c.id === userCompany)?.name : null
      };


      const doc = await reportsService.generatePDF(filteredAndSortedDonations, state.charities, formattedSummaryStats, chartData, metadata);
      doc.save("donations-report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF. Please try again.");
    }
  };


  const getCharitableImpact = (donations) => {
    const impact = {};
    donations.forEach(donation => {
      donation.palletGroups.forEach(group => {
        if (!impact[group.charityId]) {
          impact[group.charityId] = {
            charityId: group.charityId,
            palletCount: 0,
            donationCount: 0,
            totalValue: 0
          };
        }
        impact[group.charityId].palletCount += group.palletQuantity;
        impact[group.charityId].donationCount += 1;
        impact[group.charityId].totalValue += (donation.fairMarketValue / donation.palletCount) * group.palletQuantity;
      });
    });
    return Object.values(impact);
  };


  const getFilteredCharitableImpact = (impact) => {
    const sortedImpact = impact.sort((a, b) => b.totalValue - a.totalValue);
    switch (charityImpactFilter) {
      case 'top5':
        return sortedImpact.slice(0, 5);
      case 'top10':
        return sortedImpact.slice(0, 10);
      default:
        return sortedImpact;
    }
  };


  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;


  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="reports-container">
          <div className="reports-content">
            <h1><FaFileAlt /> Completed Donations Report</h1>
            <SummaryCards stats={summaryStats} />
            <FilterBar
              filters={state.filters}
              distributionCenters={state.distributionCenters}
              companies={state.companies}
              charities={state.charities}
              dispatch={dispatch}
              userRole={userRole}
              userCompany={userCompany}
              userDistributionCenters={userDistributionCenters}
            />
            <DonationMap
              distributionCenters={filteredDCs}
              charities={filteredCharities}
            />
            <ChartSection
              cumulativeData={getCumulativeDonationsData()}
              charityData={getCharityChartData()}
            />
            <div className="table-actions">
              <button onClick={handleCSVExport} className="btn btn-primary">
                <FaFileCsv /> Export CSV
              </button>
              <button onClick={handlePDFExport} className="btn btn-primary">
                <FaFilePdf /> Export PDF
              </button>
              <button onClick={fetchData} className="btn btn-primary">
                <FaSyncAlt /> Refresh Data
              </button>
            </div>
            <CompletedDonationsTable
              donations={paginatedDonations}
              charities={state.charities}
              sortConfig={state.sortConfig}
              onSort={handleSort}
            />
            <Pagination
              currentPage={state.currentPage}
              totalItems={filteredAndSortedDonations.length}
              itemsPerPage={state.itemsPerPage}
              onPageChange={(page) => dispatch({ type: 'SET_PAGE', payload: page })}
              onItemsPerPageChange={(items) => dispatch({ type: 'SET_ITEMS_PER_PAGE', payload: items })}
            />
            <CharityImpactSection
              charitableImpact={getFilteredCharitableImpact(getCharitableImpact(filteredAndSortedDonations))}
              charities={state.charities}
              filter={charityImpactFilter}
              onFilterChange={setCharityImpactFilter}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};


const SummaryCards = ({ stats }) => (
  <div className="summary-cards">
    <div className="stat-card">
      <FaFileAlt className="icon" />
      <h3>Completed Donations</h3>
      <p>{formatNumber(stats.totalDonations)}</p>
    </div>
    <div className="stat-card">
      <FaFileInvoiceDollar className="icon" />
      <h3>Value of Donations</h3>
      <p>{formatAbbreviatedCurrency(stats.totalFairMarketValue)}</p>
    </div>
    <div className="stat-card">
      <FaMoneyBillWave className="icon" />
      <h3>Estimated Tax Benefits</h3>
      <p>{formatAbbreviatedCurrency(stats.totalEstTaxSavings)}</p>
    </div>
    <div className="stat-card">
      <FaPallet className="icon" />
      <h3>Total Pallets</h3>
      <p>{formatNumber(stats.totalPallets)}</p>
    </div>
    <div className="stat-card">
      <FaUsers className="icon" />
      <h3>Estimated People Helped</h3>
      <p>{formatNumber(stats.totalQuantity)}</p>
    </div>
  </div>
);


const FilterBar = React.memo(({
  filters,
  distributionCenters,
  companies,
  charities,
  dispatch,
  userRole,
  userCompany,
  userDistributionCenters
}) => {
  const handleFilterChange = (name, value) => {
    dispatch({ type: 'SET_FILTER', payload: { name, value } });
  };


  const handleDateRangeChange = (update) => {
    dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { start: update[0], end: update[1] } } });
  };


  const resetFilters = () => {
    dispatch({ type: 'RESET_FILTERS' });
  };


  // Filter distribution centers based on user role and assignments
  const filteredDistributionCenters = (() => {
    switch (userRole) {
      case 'SystemAdmin':
        return distributionCenters;
      case 'Admin':
        return distributionCenters.filter(dc => dc.company === userCompany);
      case 'User':
        return distributionCenters.filter(dc => userDistributionCenters.includes(dc.id));
      default:
        return [];
    }
  })();


  return (
    <div className="filters">
      <div className="filter-group">
        <FaSearch className="icon" />
        <input
          type="text"
          placeholder="Search donations..."
          value={filters.searchTerm}
          onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
        />
      </div>


      <div className="filter-group">
        <FaWarehouse className="icon" />
        <select
          value={filters.distributionCenter}
          onChange={(e) => handleFilterChange('distributionCenter', e.target.value)}
        >
          <option value="all">All Distribution Centers</option>
          {filteredDistributionCenters.map(dc => (
            <option key={dc.id} value={dc.id}>
              {dc.name || `${dc.address.street}, ${dc.address.city}, ${dc.address.state} ${dc.address.zip}`}
            </option>
          ))}
        </select>
      </div>


      {userRole === 'SystemAdmin' && (
        <div className="filter-group">
          <FaBuilding className="icon" />
          <select
            value={filters.company}
            onChange={(e) => handleFilterChange('company', e.target.value)}
          >
            <option value="all">All Companies</option>
            {companies.map(company => (
              <option key={company.id} value={company.id}>{company.name}</option>
            ))}
          </select>
        </div>
      )}


      <div className="filter-group">
        <FaHandHoldingHeart className="icon" />
        <select
          value={filters.charity}
          onChange={(e) => handleFilterChange('charity', e.target.value)}
        >
          <option value="all">All Charities</option>
          {Object.values(charities).map(charity => (
            <option key={charity.id} value={charity.id}>{charity.name}</option>
          ))}
        </select>
      </div>


      <div className="filter-group">
        <FaCalendarAlt className="icon" />
        <DatePicker
          selectsRange={true}
          startDate={filters.dateRange.start}
          endDate={filters.dateRange.end}
          onChange={handleDateRangeChange}
          isClearable={true}
          placeholderText="Select date range"
        />
      </div>


      <button className="btn btn-reset" onClick={resetFilters}>
        <FaUndo /> Reset Filters
      </button>
    </div>
  );
});


const ChartSection = React.memo(({ cumulativeData, charityData }) => {
  return (
    <div className="chart-section">
      <div className="chart-row">
        <div className="chart cumulative-chart">
          <h3><FaChartLine /> Cumulative Donations Over Time</h3>
          <Line
            data={cumulativeData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'month',
                    displayFormats: {
                      month: 'MMM yyyy'
                    }
                  },
                  title: {
                    display: true,
                    text: 'Date'
                  }
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: 'Cumulative Value ($)'
                  },
                  ticks: {
                    callback: (value) => `$${value.toLocaleString()}`
                  }
                }
              },
              interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
              },
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    title: (context) => {
                      const donation = context[0].raw.donation;
                      return `Donation ID: ${donation.id}`;
                    },
                    label: (context) => {
                      const donation = context.raw.donation;
                      return [
                        `Date: ${new Date(donation.completionDate).toLocaleDateString()}`,
                        `Value: $${donation.fairMarketValue.toLocaleString()}`,
                        `Cumulative: $${context.parsed.y.toLocaleString()}`,
                        `Item: ${donation.description}`
                      ];
                    }
                  }
                },
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                }
              },
              elements: {
                line: {
                  tension: 0.3
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 5
                }
              }
            }}
          />
        </div>
        <div className="chart charity-chart">
          <h3><FaChartPie /> Donations by Charity</h3>
          <Pie
            data={charityData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right',
                  align: 'center'
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const label = context.label || '';
                      const value = context.parsed || 0;
                      const total = context.dataset.data.reduce((a, b) => a + b, 0);
                      const percentage = ((value / total) * 100).toFixed(1);
                      return `${label}: ${value} pallets (${percentage}%)`;
                    }
                  }
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
});


const CompletedDonationsTable = React.memo(({ donations, charities, sortConfig, onSort }) => {
  const calculateEstTaxSaving = (costBasis, fairMarketValue) => {
    const saving = costBasis + (1/2) * (fairMarketValue - costBasis);
    return Math.min(saving, 2 * costBasis);
  };


  // Helper function to convert object of urls to array
  const getUrlsArray = (urlsObject) => {
    if (!urlsObject) return [];
    return Object.entries(urlsObject)
      .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
      .map(([key, url]) => ({ key, url }));
  };


  return (
    <div className="completed-donations-table-container">
      <table className="completed-donations-table">
        <thead>
          <tr>
            <th onClick={() => onSort('id')}>Donation ID {sortConfig.key === 'id' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => onSort('completionDate')}>Completion Date {sortConfig.key === 'completionDate' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => onSort('itemDescription')}>Item Description {sortConfig.key === 'itemDescription' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => onSort('palletCount')}>Pallet Count {sortConfig.key === 'palletCount' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => onSort('quantity')}>Quantity {sortConfig.key === 'quantity' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => onSort('fairMarketValue')}>Fair Market Value {sortConfig.key === 'fairMarketValue' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th>Est. Tax Saving</th>
            <th>Charities</th>
            <th>Documents</th>
          </tr>
        </thead>
        <tbody>
          {donations.map(donation => (
            <tr key={donation.id}>
              <td>{donation.id}</td>
              <td>{new Date(donation.completionDate).toLocaleDateString()}</td>
              <td>{donation.description}</td>
              <td>{formatNumber(donation.palletCount)}</td>
              <td>{formatNumber(donation.quantity)}</td>
              <td>{formatCurrency(donation.fairMarketValue)}</td>
              <td>{formatCurrency(calculateEstTaxSaving(donation.costBasis, donation.fairMarketValue))}</td>
              <td>
                {donation.palletGroups.map(group =>
                  charities[group.charityId] ? charities[group.charityId].name : 'Unknown'
                ).join(", ")}
              </td>
              <td>
                <div className="action-buttons">
                  {/* Handle multiple FMV assessments */}
                  {getUrlsArray(donation.fmvAssessmentUrls).map(({ key, url }) => (
                    <a
                      key={`fmv-${key}`}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-download btn-sm"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={`Download FMV Assessment ${key}`}
                    >
                      <FaFileInvoiceDollar />
                      {Object.keys(donation.fmvAssessmentUrls || {}).length > 1 &&
                        <span className="document-index">{key}</span>}
                    </a>
                  ))}


                  {/* Quote PDF button */}
                  <a
                    href={donation.quotePdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-download btn-sm"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Download Quote"
                  >
                    <FaFileContract />
                  </a>


                  {/* Handle multiple tax receipts */}
                  {getUrlsArray(donation.taxReceiptUrls).map(({ key, url }) => (
                    <a
                      key={`receipt-${key}`}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-download btn-sm"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={`Download Tax Receipt ${key}`}
                    >
                      <FaReceipt />
                    </a>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});


const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange, onItemsPerPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);


  return (
    <div className="pagination">
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      <span>{currentPage} of {totalPages}</span>
      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
      <select value={itemsPerPage} onChange={(e) => onItemsPerPageChange(Number(e.target.value))}>
        <option value={10}>10 per page</option>
        <option value={25}>25 per page</option>
        <option value={50}>50 per page</option>
      </select>
    </div>
  );
};


const CharityImpactSection = React.memo(({ charitableImpact, charities, filter, onFilterChange }) => (
  <div className="charity-impact-section">
    <h3>Charitable Impact</h3>
    <div className="charity-impact-controls">
      <select value={filter} onChange={(e) => onFilterChange(e.target.value)}>
        <option value="all">All Charities</option>
        <option value="top5">Top 5 Charities</option>
        <option value="top10">Top 10 Charities</option>
      </select>
    </div>
    <table className="charity-impact-table">
      <thead>
        <tr>
          <th>Charity Name</th>
          <th>Pallets Received</th>
          <th>Donations Received</th>
          <th>Total Value Received</th>
        </tr>
      </thead>
      <tbody>
        {charitableImpact.map((impact) => {
          const charity = charities[impact.charityId] || {};
          return (
            <tr key={impact.charityId}>
              <td>{charity.name || 'Unknown Charity'}</td>
              <td>{formatNumber(impact.palletCount)}</td>
              <td>{formatNumber(impact.donationCount)}</td>
              <td>{formatCurrency(impact.totalValue)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
    <CharityProfileCards charitableImpact={charitableImpact} charities={charities} />
  </div>
));


const CharityProfileCards = ({ charitableImpact, charities }) => (
  <div className="charity-profile-cards">
    <h3>Receiving Charity Profiles</h3>
   
    <div className="charity-cards-container">
      {charitableImpact.map((impact) => {
        const charity = charities[impact.charityId] || {};


        // Format the value to K/M notation
        const formatValue = (value) => {
          if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(1)}M`;
          } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(1)}K`;
          }
          return `$${value.toFixed(0)}`;
        };


        return (
          <div key={impact.charityId} className="charity-card">
            <div className="charity-card-banner">
              <div className="charity-logo-wrapper">
                <img
                  src={charity.logoUrl || '/api/placeholder/80/80'}
                  alt={`${charity.name} logo`}
                  className="charity-logo"
                />
              </div>
            </div>


            <div className="charity-card-content">
              <h4 className="charity-name">{charity.name || 'Unknown Charity'}</h4>
             
              <p className="charity-description">
                {charity.description || 'No description available'}
              </p>


              <div className="charity-contact-info">
                {charity.address && (
                  <div className="contact-item">
                    <FaMapMarkerAlt className="contact-icon" />
                    <span>
                      {`${charity.address.city}, ${charity.address.state}`}
                    </span>
                  </div>
                )}
               
                {charity.contactInfo?.phone && (
                  <div className="contact-item">
                    <FaPhoneAlt className="contact-icon" />
                    <span>{charity.contactInfo.phone}</span>
                  </div>
                )}
               
                {charity.contactInfo?.email && (
                  <div className="contact-item">
                    <FaEnvelope className="contact-icon" />
                    <a href={`mailto:${charity.contactInfo.email}`}>
                      {charity.contactInfo.email}
                    </a>
                  </div>
                )}
               
                {charity.contactInfo?.website && (
                  <div className="contact-item">
                    <FaGlobe className="contact-icon" />
                    <a
                      href={charity.contactInfo.website.startsWith('http') ?
                        charity.contactInfo.website :
                        `https://${charity.contactInfo.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="website-link"
                    >
                      Visit Website
                      <FaExternalLinkAlt className="external-link-icon" />
                    </a>
                  </div>
                )}
              </div>


              <div className="charity-impact-stats">
                <div className="impact-stat">
                  <div className="stat-icon">
                    <FaBoxOpen />
                  </div>
                  <div className="stat-content">
                    <span className="stat-value">{impact.palletCount}</span>
                    <span className="stat-label">Pallets</span>
                  </div>
                </div>


                <div className="impact-stat">
                  <div className="stat-icon">
                    <FaHandHoldingHeart />
                  </div>
                  <div className="stat-content">
                    <span className="stat-value">{impact.donationCount}</span>
                    <span className="stat-label">Donations</span>
                  </div>
                </div>


                <div className="impact-stat">
                  <div className="stat-icon">
                    <FaDollarSign />
                  </div>
                  <div className="stat-content">
                    <span className="stat-value">{formatValue(impact.totalValue)}</span>
                    <span className="stat-label">Value</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);


const DonationMap = React.memo(({ distributionCenters, charities }) => {
  const mapRef = useRef(null);
  const [mapError, setMapError] = useState(null);


  useEffect(() => {
    const initMap = async () => {
      if (!mapRef.current) {
        try {
          mapRef.current = await mapService.createMap('map-container', {
            DistributionCenterIcon: <FaWarehouse />,
            CharityIcon: <FaHeart />
          });
        } catch (error) {
          console.error('Error initializing map:', error);
          setMapError('Failed to initialize map. Please try refreshing the page.');
          return;
        }
      }


      if (mapRef.current && mapRef.current.updateMarkers) {
        mapRef.current.updateMarkers(distributionCenters, charities);
      }
    };


    initMap();
  }, [distributionCenters, charities]);


  if (mapError) {
    return <div className="map-error">{mapError}</div>;
  }


  return (
    <div className="map-container">
      <div className="map-controls">
        <button onClick={() => mapRef.current.map.setZoom(4)}><FaMapMarkedAlt /> Reset View</button>
        <button onClick={() => mapRef.current.updateMarkers(distributionCenters, [])}><FaWarehouse /> Distribution Centers</button>
        <button onClick={() => mapRef.current.updateMarkers([], charities)}><FaHandHoldingHeart /> Charities</button>
        <button onClick={() => mapRef.current.updateMarkers(distributionCenters, charities)}><FaLayerGroup /> Show All</button>
      </div>
      <div id="map-container" style={{ height: '400px', width: '100%' }} />
    </div>
  );
});


export default Reports;