// src/services/messageService.js
// TODO: import slackService after it's updated and add to sendMessage here
//import { slackService } from './slackService';

class MessageService {
  constructor() {
    this.authToken = btoa(`${process.env.REACT_APP_TWILIO_ACCOUNT_SID}:${process.env.REACT_APP_TWILIO_AUTH_TOKEN}`);
    this.appBaseUrl = 'https://app.donatingsimplified.com';
  }

  
  // ============ Core Messaging Functionality ============

  /**
   * Format phone number to E.164 format
   * @private
   * @param {string} phone - The phone number to format
   * @returns {string} E.164 formatted phone number
   */
  formatPhoneNumber(phone) {
    if (!phone) {
      throw new Error('Phone number is required');
    }

    // Remove all non-numeric characters
    let cleaned = phone.replace(/\D/g, '');

    // Handle US numbers without country code
    if (cleaned.length === 10) {
      cleaned = '1' + cleaned;
    }

    // Remove leading 1 if present to standardize
    if (cleaned.startsWith('1')) {
      cleaned = cleaned.substring(1);
    }

    // Validate length after cleaning (should be 10 digits for US numbers)
    if (cleaned.length !== 10) {
      throw new Error('Invalid phone number length');
    }

    // Format to E.164
    return `+1${cleaned}`;
  }

  /**
   * Validate phone number format (E.164)
   * @private
   * @param {string} phone - The phone number to validate
   * @returns {boolean} Whether the phone number is valid
   */
  validatePhoneNumber(phone) {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  }

    /**
   * Determine the type of message being sent based on content
   * @private
   * @param {string} messageBody - The content of the message
   * @returns {string} The type of message
   */
  determineMessageType(messageBody) {
    if (messageBody.includes('Donation Successfully Uploaded')) return 'Donation Created';
    if (messageBody.includes('New Quote Created')) return 'Quote Created';
    if (messageBody.includes('Quote Has Been Approved')) return 'Quote Approved';
    if (messageBody.includes('Quote Has Been Rejected')) return 'Quote Rejected';
    if (messageBody.includes('quote has been completed')) return 'Quote Completed';
    if (messageBody.includes('Donation Completed')) return 'Donation Completed';
    if (messageBody.includes('Charity Delivery Completed')) return 'Delivery Completed';
    if (messageBody.includes('Pickup Approval Request')) return 'Pickup Request';
    if (messageBody.includes('Pickup Request Approved')) return 'Pickup Approved';
    if (messageBody.includes('Pickup Request Rejected')) return 'Pickup Rejected';
    return 'Other';
  }

  /**
   * Send a message using Twilio
   * @private
   * @param {string} userPhone - The recipient's phone number
   * @param {string} messageBody - The message content to send
   * @returns {Promise<Object>} Result of the message sending operation
   */
  // Within the MessageService class

  async sendMessage(userPhone, messageBody) {
    try {
      // Format the phone number before validation
      const formattedPhone = this.formatPhoneNumber(userPhone);
      
      if (!this.validatePhoneNumber(formattedPhone)) {
        throw new Error('Invalid phone number format after formatting');
      }
  
      const formData = new URLSearchParams();
      formData.append('To', formattedPhone);
      formData.append('From', process.env.REACT_APP_TWILIO_PHONE_NUMBER);
      formData.append('Body', messageBody);
  
      const response = await fetch(
        `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${this.authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData
        }
      );
  
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send message');
      }
  
      // Store the Twilio result
      const twilioResult = {
        success: true,
        messageId: data.sid
      };
  
      /* Temporarily commented out Slack integration
      try {
        const messageType = this.determineMessageType(messageBody);
        await slackService.sendSmsNotification({
          recipient: formattedPhone,
          messageBody: messageBody,
          messageType: messageType,
          metadata: {
            twilioMessageId: twilioResult.messageId,
            timestamp: new Date().toISOString()
          }
        });
      } catch (slackError) {
        console.error('Error mirroring to Slack:', slackError);
      }
      */
  
      // Return original Twilio result
      return twilioResult;
  
    } catch (error) {
      console.error('Error sending message:', error);
      throw new Error(`Failed to send message: ${error.message}`);
    }
  }

  // ============ Formatting Utilities ============

  /**
   * Format currency values consistently
   * @private
   * @param {number} amount - The amount to format
   * @returns {string} Formatted currency string
   */
  formatCurrency(amount) {
    if (typeof amount !== 'number') return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  }

  /**
   * Format date consistently
   * @private
   * @param {string|Date} date - The date to format
   * @returns {string} Formatted date string
   */
  formatDate(date) {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString();
  }

  /**
   * Format date and time consistently
   * @private
   * @param {string|Date} datetime - The datetime to format
   * @returns {string} Formatted datetime string
   */
  formatDateTime(datetime) {
    if (!datetime) return 'N/A';
    return new Date(datetime).toLocaleString();
  }

  /**
   * Generate URL for specific pages
   * @private
   * @param {string} path - The path to append to base URL
   * @param {string} [id] - Optional ID to append to path
   * @returns {string} Complete URL
   */
  getPageUrl(path, id = '') {
    return `${this.appBaseUrl}/${path}${id ? `/${id}` : ''}`;
  }

  // ============ New Donation Notifications ============

/**
 * Send notification when a new donation is successfully created
 * @param {string} userPhone - The recipient's phone number
 * @param {Object} donationDetails - Donation details
 */
async sendDonationCreatedNotification(userPhone, donationDetails) {
  const message = this.formatDonationCreatedMessage(donationDetails);
  return this.sendMessage(userPhone, message);
}

/**
 * Format message for new donation creation
 * @private
 */
formatDonationCreatedMessage(donationDetails) {
  return `
Donation Successfully Uploaded!

Total Items: ${donationDetails.items.length}
Total Value: ${this.formatCurrency(donationDetails.totalEstimatedValue)}
Total Pallets: ${donationDetails.totalPalletCount}
Distribution Center: ${donationDetails.distributionCenterName}

View your donation details at:
${this.getPageUrl('my-impact-dashboard', donationDetails.id)}

Thank you for your donation! Our team will begin processing it shortly.
  `.trim();
}

  // ============ Quote Notifications ============

  /**
   * Send notification when a new quote is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendNewQuoteNotification(userPhone, quoteDetails) {
    const message = this.formatNewQuoteMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for new quote
   * @private
   */
  formatNewQuoteMessage(quoteDetails) {
    return `
New Quote Created!

Quote ID: ${quoteDetails.id}
Item: ${quoteDetails.itemDescription}
Pallets: ${quoteDetails.palletCount}
Total Amount: ${this.formatCurrency(quoteDetails.amount)}
Valid Until: ${this.formatDate(quoteDetails.validityPeriod)}

Review your quote at ${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is approved
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteApprovalNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteApprovalMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote approval
   * @private
   */
  formatQuoteApprovalMessage(quoteDetails) {
    return `
Your Quote Has Been Approved!

Quote ID: ${quoteDetails.id}
Company Name: ${quoteDetails.companyName}
Distribution Center: ${quoteDetails.distributionCenterName}
Amount: ${this.formatCurrency(quoteDetails.amount)}
Approved By: ${quoteDetails.approverName}
Approval Date: ${this.formatDate(quoteDetails.approvalTimestamp)}

View your approved quote at ${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is rejected
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteRejectionNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteRejectionMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote rejection
   * @private
   */
  formatQuoteRejectionMessage(quoteDetails) {
    return `
Your Quote Has Been Rejected

Quote ID: ${quoteDetails.id}
Distribution Center: ${quoteDetails.distributionCenterName}
Rejected By: ${quoteDetails.rejectedBy}
Rejection Date: ${this.formatDate(quoteDetails.rejectionTimestamp)}
Reason: ${quoteDetails.rejectionReason}

Please review the rejection details and submit a new quote if needed:
${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteCompletionNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteCompletionMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote completion
   * @private
   */
  formatQuoteCompletionMessage(quoteDetails) {
    // Format charity assignments if they exist
    const charityAssignments = quoteDetails.assignedCharities?.map(charity => 
      `    ❤️ ${charity.charityName} (${charity.locationName})\n      ${charity.palletCount} pallets`
    ).join('\n') || 'No charity assignments';
  
    return `🎉 Quote Completed!
  
  ID: ${quoteDetails.id}
  Completed: ${this.formatDate(quoteDetails.completedAt)}
  
  📋 Quote Details
      Item: ${quoteDetails.description}
      Value: ${this.formatCurrency(quoteDetails.fairMarketValue)}
      Location: ${quoteDetails.distributionCenterName}
  
      Delivered to:
  ${charityAssignments}
  
  Your documents are ready at:
  ${this.getPageUrl('reports')}
  
  Thank you for helping make an impact!`.trim();
  }

  // ============ Logistics Notifications ============

  /**
   * Send notification when a charity delivery is completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Delivery completion details
   */
  async sendCharityDeliveryCompletionNotification(userPhone, details) {
    const message = this.formatCharityDeliveryCompletionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for charity delivery completion
   * @private
   */
  formatCharityDeliveryCompletionMessage(details) {
    return `🎉 Delivery Completed!
  
  ID: ${details.taskId}
  Accepted: ${this.formatDate(details.acceptedDate)}
  
  📋 Delivery Details
      Item: ${details.itemDescription}
      Pallets: ${details.palletQuantity}
      Location: ${details.distributionCenterName}
  
      Received by:
      ❤️ ${details.charityName} (${details.charityLocationName})
  
  Your delivery documentation and tax receipt are ready at:
  ${this.getPageUrl('logistics/tasks', details.taskId)}
  
  Thank you for helping make an impact!`.trim();
  }

  /**
   * Send notification when a pickup approval request is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Pickup request details
   */
  async sendPickupApprovalRequestNotification(userPhone, details) {
    const message = this.formatPickupApprovalRequestMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for pickup approval request
   * @private
   */
  formatPickupApprovalRequestMessage(details) {
    return `
Pickup Approval Request

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Requested Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}

Please review and approve the pickup request:
${this.getPageUrl('logistics/approvals')}

Note: This request requires your approval within 24 hours.
    `.trim();
  }

  /**
   * Send notification when a pickup request decision (approval/rejection) is made
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Decision details
   * @param {string} details.status - 'approved' or 'rejected'
   * @param {string} details.approverName - Name of the person who made the decision
   * @param {string} details.itemDescription - Description of items
   * @param {string} details.distributionCenterName - Name of distribution center
   * @param {string} details.charityName - Name of charity
   * @param {number} details.palletQuantity - Number of pallets
   * @param {string} details.pickupDateTime - Pickup date and time
   * @param {string} details.deliveryDate - Delivery date
   */
  async sendPickupRequestDecisionNotification(userPhone, details) {
    const message = details.status === 'approved' 
      ? this.formatPickupRequestApprovalMessage(details)
      : this.formatPickupRequestRejectionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for pickup request approval
   * @private
   */
  formatPickupRequestApprovalMessage(details) {
    return `
Pickup Request Approved!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Confirmed Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}
Approved By: ${details.approverName}

Your pickup request has been approved. Please ensure shipping and charity acceptance remain available for the scheduled pickup date.

View the approved pickup details:
${this.getPageUrl('logistics/tasks', details.taskId)}
    `.trim();
  }

  /**
   * Format message for pickup request rejection
   * @private
   */
  formatPickupRequestRejectionMessage(details) {
    return `
Pickup Request Rejected

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Requested Pickup: ${this.formatDateTime(details.pickupDateTime)}
Rejected By: ${details.approverName}

Your pickup request has been rejected. Please contact the distribution center for more information or submit a new request.

View the request details:
${this.getPageUrl('logistics/tasks', details.taskId)}
    `.trim();
  }

  // ============ Donation Notifications ============

  /**
   * Send notification when a donation is marked as completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Donation completion details
   */
  async sendDonationCompletionNotification(userPhone, details) {
    const message = this.formatDonationCompletionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for completed donation
   * @private
   */
  formatDonationCompletionMessage(details) {
    // Handle quotes and their charity assignments, with optimized indentation
    const quotesList = details.quotes?.map(quote => {
      const charityAssignments = quote.assignedCharities?.map(charity => 
        `    ❤️ ${charity.charityName} (${charity.locationName})\n      ${charity.palletCount} pallets`
      ).join('\n') || 'No charity assignments';
  
      return `📋 Quote ${quote.id}
      Item: ${quote.description}
      Value: ${this.formatCurrency(quote.fairMarketValue)}
      Location: ${quote.distributionCenterName}
  
      Delivered to:
  ${charityAssignments}`
    }).join('\n\n') || 'No quotes available';
  
    // Calculate total stats
    const totalPallets = details.quotes?.reduce((total, quote) => 
      total + (quote.assignedCharities?.reduce((sum, charity) => 
        sum + (charity.palletCount || 0), 0) || 0), 0
    ) || 0;
  
    const totalValue = details.quotes?.reduce((total, quote) => 
      total + (quote.fairMarketValue || 0), 0
    ) || 0;
  
    const totalWeight = details.quotes?.reduce((total, quote) => 
      total + (quote.totalWeight || 0), 0
    ) || 0;
  
    // Calculate total cost basis and estimated savings
    const totalCostBasis = details.quotes?.reduce((total, quote) => 
      total + (quote.costBasis || 0), 0
    ) || 0;
  
    const estimatedSavings = Math.min(
      totalCostBasis + (totalValue - totalCostBasis) / 2,
      2 * totalCostBasis
    );
  
    // Get unique charity count
    const uniqueCharities = new Set(
      details.quotes?.flatMap(quote => 
        quote.assignedCharities?.map(charity => 
          `${charity.charityId}-${charity.locationId}`
        ) || []
      ) || []
    );
  
    return `🎉 Donation Completed!
  
  ID: ${details.id}
  Completed: ${this.formatDate(details.completionDate)}
  
  IMPACT SUMMARY
  📦 Quotes: ${details.quotes?.length || 0}
  🏢 Charities: ${uniqueCharities.size}
  📏 Pallets: ${totalPallets}
  💰 Value: ${this.formatCurrency(totalValue)}
  💵 Est. Tax Savings: ${this.formatCurrency(estimatedSavings)}
  ♻️ Landfill Saved: ${totalWeight.toLocaleString()} lbs
  
  DELIVERY DETAILS
  ${quotesList}
  
  Your tax receipts and documents are ready at:
  ${this.getPageUrl('reports')}
  
  Thank you for your generous donation!`.trim();
  }
}

export const messageService = new MessageService();