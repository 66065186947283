// src/services/clientOrganizationService.js
import GoopPresentation from '../components/GoopPresentation';
// Import other client presentations as needed
// import CompanyXPresentation from '../components/CompanyXPresentation';
// import CompanyYPresentation from '../components/CompanyYPresentation';

// Configuration object for client organizations
const CLIENT_ORGANIZATIONS = {
  'goop': {
    name: 'Goop',
    whitelistedEmails: [
      'client@goop.com',
      'executive@goop.com',
      'goop@donatingsimplified.com',
      'goop@simpli.supply',
      'tommy@wave.vip',
      'tpraskin@gmail.com',
      'goop@donatingsimplified.com'
      // Add more goop emails here
    ],
    presentationComponent: GoopPresentation,
    previewPath: '/client-preview/goop'
  },
  // Example of how to add more organizations
  // 'companyX': {
  //   name: 'Company X',
  //   whitelistedEmails: ['client@companyx.com'],
  //   presentationComponent: CompanyXPresentation,
  //   previewPath: '/client-preview/companyx'
  // }
};

export const clientOrganizationService = {
  // Check if email is whitelisted and return organization info
  getOrganizationForEmail: (email) => {
    const lowerEmail = email.toLowerCase();
    const organization = Object.values(CLIENT_ORGANIZATIONS).find(org => 
      org.whitelistedEmails.includes(lowerEmail)
    );
    return organization || null;
  },

  // Get organization by ID
  getOrganizationById: (orgId) => {
    return CLIENT_ORGANIZATIONS[orgId] || null;
  },

  // Get all organization IDs
  getAllOrganizationIds: () => {
    return Object.keys(CLIENT_ORGANIZATIONS);
  }
};