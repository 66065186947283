import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DonationDetailsPopup } from './DonationTracker';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import '../styles/DonationRoute.css';

const DonationRoute = () => {
  const { donationId } = useParams();
  const navigate = useNavigate();
  const [donation, setDonation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDonation = async () => {
      try {
        setLoading(true);
        const donationData = await donationService.getById(donationId);
        
        if (!donationData) {
          throw new Error('Donation not found');
        }

        // Fetch distribution center details
        const dc = await distributionCenterService.getById(donationData.distributionCenterId);
        
        // Add distribution center name to donation data
        const donationWithDC = {
          ...donationData,
          distributionCenterName: dc.name
        };

        setDonation(donationWithDC);
      } catch (err) {
        console.error('Error fetching donation:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (donationId) {
      fetchDonation();
    }
  }, [donationId]);

  if (loading) {
    return (
      <div className="donation-route-container">
        <div className="loading-container">
          <div className="loading-spinner" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="donation-route-container">
        <div className="error-container">
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }

  if (!donation) {
    return (
      <div className="donation-route-container">
        <div className="not-found-container">
          <p>Donation not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="donation-route-container">
      <div className="donation-details-standalone">
        <DonationDetailsPopup 
          donation={donation}
          onClose={() => navigate('/my-impact-dashboard')}
          isStandalone={true}
        />
      </div>
    </div>
  );
};

export default DonationRoute;