import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, query, where } from 'firebase/firestore';

const COLLECTION_NAME = 'completedDeliveries';

class CompletedDeliveryError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'CompletedDeliveryError';
    this.code = code;
  }
}

const validateDeliveryData = (data) => {
  const requiredFields = [
    'taskId',
    'quoteId',
    'donationId',
    'charityId',
    'charityName',
    'charityLocationId',
    'charityLocationName',
    'charityAddress',
    'distributionCenterId',
    'distributionCenterName',
    'distributionCenterAddress',
    'taxReceiptUrl',
    'acceptedByCharityDate',
    'palletQuantity'
  ];

  for (const field of requiredFields) {
    if (!data[field]) {
      throw new CompletedDeliveryError(`Missing required field: ${field}`, 'INVALID_DATA');
    }
  }
};

export const completedDeliveriesService = {
  async createCompletedDelivery(taskData, taxReceiptUrl, acceptedDate) {
    try {
      // Prepare the delivery data
      const deliveryData = {
        id: doc(collection(db, COLLECTION_NAME)).id,
        taskId: taskData.id,
        quoteId: taskData.quoteId,
        donationId: taskData.donationId,
        itemId: taskData.itemId,
        itemDescription: taskData.itemDescription,
        
        // Charity information
        charityId: taskData.charityId,
        charityName: taskData.charityName,
        charityLocationId: taskData.charityLocationId,
        charityLocationName: taskData.charityLocationName,
        charityAddress: taskData.charityAddress,
        primaryContact: taskData.primaryContact,
        
        // Distribution center information
        distributionCenterId: taskData.distributionCenterId,
        distributionCenterName: taskData.distributionCenterName,
        distributionCenterAddress: taskData.distributionCenterAddress,
        
        // Delivery details
        palletQuantity: taskData.palletQuantity,
        palletGroup: taskData.palletGroup,
        pickupDateTime: taskData.pickupDateTime,
        deliveryDate: taskData.deliveryDate,
        acceptedByCharityDate: acceptedDate,
        
        // Documentation
        taxReceiptUrl: taxReceiptUrl,
        bolPdfUrl: taskData.bolPdfUrl,
        shippingQuoteUrl: taskData.shippingQuoteUrl,
        
        // Metadata
        status: 'completed',
        completionDate: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        lastUpdatedAt: new Date().toISOString(),
        createdBy: taskData.lastUpdatedBy || taskData.createdBy,
        lastUpdatedBy: taskData.lastUpdatedBy
      };

      // Validate the data
      validateDeliveryData(deliveryData);

      // Create the document in Firestore
      const docRef = doc(collection(db, COLLECTION_NAME), deliveryData.id);
      await setDoc(docRef, deliveryData);
      
      return deliveryData;
    } catch (error) {
      console.error('Error creating completed delivery:', error);
      throw error instanceof CompletedDeliveryError ? error : 
        new CompletedDeliveryError(error.message, 'CREATE_ERROR');
    }
  },

  async getById(id) {
    try {
      if (!id) throw new CompletedDeliveryError('ID is required', 'INVALID_PARAMETER');
      const docRef = doc(collection(db, COLLECTION_NAME), id);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'GET_ERROR');
    }
  },

  async getByTaskId(taskId) {
    try {
      if (!taskId) throw new CompletedDeliveryError('Task ID is required', 'INVALID_PARAMETER');
      const q = query(collection(db, COLLECTION_NAME), where("taskId", "==", taskId));
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return docs[0] || null; // Return first matching delivery or null
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  },

  async getByQuoteId(quoteId) {
    try {
      if (!quoteId) throw new CompletedDeliveryError('Quote ID is required', 'INVALID_PARAMETER');
      const q = query(collection(db, COLLECTION_NAME), where("quoteId", "==", quoteId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  },

  async getByCharity(charityId, locationId = null) {
    try {
      if (!charityId) throw new CompletedDeliveryError('Charity ID is required', 'INVALID_PARAMETER');
      
      let q = query(collection(db, COLLECTION_NAME), where("charityId", "==", charityId));
      if (locationId) {
        q = query(q, where("charityLocationId", "==", locationId));
      }
      
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  },

  async getByDistributionCenter(distributionCenterId) {
    try {
      if (!distributionCenterId) {
        throw new CompletedDeliveryError('Distribution center ID is required', 'INVALID_PARAMETER');
      }
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", distributionCenterId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  },

  async getByDateRange(startDate, endDate) {
    try {
      if (!startDate || !endDate) {
        throw new CompletedDeliveryError('Start and end dates are required', 'INVALID_PARAMETER');
      }
      const q = query(
        collection(db, COLLECTION_NAME),
        where("completionDate", ">=", startDate),
        where("completionDate", "<=", endDate)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  },

  async getRecentDeliveries(limit = 10) {
    try {
      if (typeof limit !== 'number' || limit <= 0) {
        throw new CompletedDeliveryError('Limit must be a positive number', 'INVALID_PARAMETER');
      }
      const deliveries = await this.getAll();
      return deliveries
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, limit);
    } catch (error) {
      throw new CompletedDeliveryError(error.message, 'QUERY_ERROR');
    }
  }
};

export default completedDeliveriesService;