// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LandingPage from './components/LandingPage.js';
import Login from './components/Login.js';
import NewCompanySignUp from './components/NewCompanySignUp';
import MyTeam from './components/MyTeam';
import StartNewDonation from './components/StartNewDonation.js';
import ManageDonations from './components/ManageDonations.js';
import CreateNewCharity from './components/CreateNewCharity.js';
import Purchases from './components/Purchase.js';
import ManageLogistics from './components/ManageLogistics.js';
import ApprovalRequests from './components/ApprovalRequests.js';
import Reports from './components/Reports.js';
import { authService } from './services/authService';
import { userService } from './services/userService';
import OptionAnalytics from './components/OptionAnalytics.js';
import Help from './components/Help.js';
import Quotes from './components/Quotes.js';
import ImpactDashboard from './components/ImpactDashboard.js';
import ExploreCharities from './components/ExploreCharities.js';
import TestFunctions from './components/TestFunctions.js';
import DonationRoute from './components/DonationRoute.js'; 
import ClientLogin from './components/ClientLogin.js';
import ClientPreviewRoute from './components/ClientPreviewRoute.js';
import './App.css';

const PrivateRoute = ({ element, allowedRoles }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await authService.getCurrentUser();
      if (currentUser) {
        const role = await userService.getUserRole(currentUser.uid);
        setUser({ ...currentUser, role });
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    // Save the attempted URL before redirecting
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/login" />;
  }

  if (allowedRoles.includes(user.role)) {
    return element;
  } else {
    return <Navigate to="/" />;
  }
};

function App() {
  return (
    <Router>
      <Routes>
        {/* Client Preview Routes */}
        <Route path="/client-login" element={<ClientLogin />} />
          <Route 
            path="/client-preview/:orgId" 
            element={<ClientPreviewRoute />} 
          />
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/signup" 
          element={<PrivateRoute element={<NewCompanySignUp />} allowedRoles={['SystemAdmin']} />} />
        <Route 
          path="/my-team" 
          element={<PrivateRoute element={<MyTeam />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/reports" 
          element={<PrivateRoute element={<Reports />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/start-new-donation" 
          element={<PrivateRoute element={<StartNewDonation />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/option-analytics" 
          element={<PrivateRoute element={<OptionAnalytics />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/manage-donations" 
          element={<PrivateRoute element={<ManageDonations />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/create-new-charity" 
          element={<PrivateRoute element={<CreateNewCharity />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/explore-charities" 
          element={<PrivateRoute element={<ExploreCharities />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/purchase-orders" 
          element={<PrivateRoute element={<Purchases />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/quotes" 
          element={<PrivateRoute element={<Quotes />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/quotes/:quoteId" 
          element={<PrivateRoute element={<Quotes />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/manage-logistics" 
          element={<PrivateRoute element={<ManageLogistics />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/manage-pickups" 
          element={<PrivateRoute element={<ApprovalRequests />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/manage-pickups/:requestId" 
          element={<PrivateRoute element={<ApprovalRequests />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/my-impact-dashboard" 
          element={<PrivateRoute element={<ImpactDashboard />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/my-impact-dashboard/:donationId" 
          element={<PrivateRoute element={<DonationRoute />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/help" 
          element={<PrivateRoute element={<Help />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/test-functions" 
          element={<PrivateRoute element={<TestFunctions />} allowedRoles={['SystemAdmin']} />} 
        />
      </Routes>
    </Router>
  );
}

export default App;