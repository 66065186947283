import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { 
  FaBoxes, 
  FaBox,
  FaFileInvoiceDollar,
  FaTruck,
  FaChevronRight,
  FaPallet,
  FaDollarSign,
  FaWarehouse,
  FaWeight,
  FaTimes,
  FaBuilding,
  FaInfoCircle,
  FaExclamationCircle,
  FaCheckCircle,
  FaClock,
  FaTimesCircle,
  FaCalendarCheck,
  FaClipboardList,
  FaChevronLeft,
} from 'react-icons/fa';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { quoteService } from '../services/quoteService';
import { donationService } from '../services/donationService';
import { logisticsService } from '../services/logisticsService';
import { distributionCenterService } from '../services/distributionCenterService';
import { approvalRequestService } from '../services/approvalRequestService';
import { charityService } from '../services/charityService';
import '../styles/DonationTracker.css';

// Status label mapping
const STATUS_LABELS = {
  // Item statuses
  'pending': 'Pending',
  'quote sent': 'Pending Quote Approval',
  'quote approved': 'Quote Approved',
  'quote rejected': 'Quote Rejected',
  'completed': 'Completed',
  
  // Task statuses
  'pendingapproval': 'Pending Pickup Approval',
  'rejected': 'Rejected',
  'scheduled': 'Scheduled',
  'completed': 'Completed'
};

// Utility Functions
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(amount);
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const getStatusIcon = (status) => {
  const iconProps = { 
    className: `status-icon status-icon--${status.toLowerCase().replace(' ', '-')}` 
  };
  
  switch (status.toLowerCase()) {
    case 'pending':
      return <FaClock {...iconProps} />;
    case 'quote sent':
      return <FaFileInvoiceDollar {...iconProps} />;
    case 'quote approved':
      return <FaCalendarCheck {...iconProps} />;
    case 'quote rejected':
      return <FaTimesCircle {...iconProps} />;
    case 'completed':
      return <FaCheckCircle {...iconProps} />;
    case 'pendingapproval':
      return <FaClock {...iconProps} />;
    case 'rejected':
      return <FaTimesCircle {...iconProps} />;
    case 'scheduled':
      return <FaTruck {...iconProps} />;
    default:
      return <FaClock {...iconProps} />;
  }
};

// State Components
const LoadingSpinner = () => (
  <div className="donation-tracker__loading">
    <div className="donation-tracker__spinner"></div>
  </div>
);

const ErrorState = ({ message }) => (
  <div className="donation-tracker__error">
    <FaExclamationCircle className="donation-tracker__error-icon" />
    <h3 className="donation-tracker__error-title">Error Loading Data</h3>
    <p className="donation-tracker__error-message">{message}</p>
  </div>
);

const EmptyState = () => (
  <div className="donation-tracker__empty">
    <FaBoxes className="donation-tracker__empty-icon" />
    <h3 className="donation-tracker__empty-title">No Pending Donations</h3>
    <p className="donation-tracker__empty-text">There are no pending donations at this time.</p>
  </div>
);

// Popup Component
export const DonationDetailsPopup = ({ donation, onClose, isStandalone = false }) => {
  const navigate = useNavigate();
  
  if (!donation) return null;

  const content = (
    <div className="popup-content" onClick={e => e.stopPropagation()}>
      <button className="popup-close" onClick={onClose}>
        <FaTimes />
      </button>
      
      <div className="popup-header">
        <FaBoxes className="popup-header-icon" />
        <div className="popup-header-content">
          <h2>Donation #{donation.id}</h2>
          <span className={`popup-status-badge popup-status-badge--${donation.status.toLowerCase()}`}>
            {STATUS_LABELS[donation.status.toLowerCase()] || donation.status}
          </span>
        </div>
      </div>

      <div className="popup-body">
        {isStandalone && (
          <button 
            onClick={() => navigate('/my-impact-dashboard')}
            className="back-button"
          >
            <FaChevronLeft /> Back to Dashboard
          </button>
        )}
        
        <div className="popup-section">
          <h3>
            <FaBuilding className="popup-section-icon" />
            Distribution Center
          </h3>
          <p>{donation.distributionCenterName}</p>
        </div>

        <div className="popup-section">
          <h3>
            <FaInfoCircle className="popup-section-icon" />
            Overview
          </h3>
          <div className="popup-stats">
            <div className="popup-stat">
              <FaDollarSign className="popup-stat-icon" />
              <div>
                <strong>Total Est. Value</strong>
                <p>{formatCurrency(donation.totalEstimatedValue)}</p>
              </div>
            </div>
            <div className="popup-stat">
              <FaPallet className="popup-stat-icon" />
              <div>
                <strong>Total Pallets</strong>
                <p>{donation.totalPalletCount}</p>
              </div>
            </div>
            <div className="popup-stat">
              <FaWeight className="popup-stat-icon" />
              <div>
                <strong>Total Weight</strong>
                <p>{donation.totalWeight} lbs</p>
              </div>
            </div>
          </div>
        </div>

        <div className="popup-section">
          <h3>
            <FaBox className="popup-section-icon" />
            Items ({donation.items.length})
          </h3>
          <div className="popup-items">
            {donation.items.map((item) => (
              <div key={item.itemID} className="popup-item">
                <div className="popup-item-header">
                  <h4>{item.description}</h4>
                  <span className={`popup-item-status popup-item-status--${item.status.toLowerCase()}`}>
                    {STATUS_LABELS[item.status.toLowerCase()] || item.status}
                  </span>
                  <span className="popup-item-sku">SKU: {item.sku}</span>
                </div>
                <div className="popup-item-details">
                  <div className="popup-item-stat">
                    <strong>Quantity:</strong> {item.quantity} {item.unitOfMeasure}
                  </div>
                  <div className="popup-item-stat">
                    <strong>Est. Value:</strong> {formatCurrency(item.estimatedValue)}
                  </div>
                  <div className="popup-item-stat">
                    <strong>Pallets:</strong> {item.palletCount}
                  </div>
                  <div className="popup-item-stat">
                    <strong>Weight:</strong> {item.totalWeight} lbs
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  // If standalone (accessed via URL), don't wrap in overlay
  if (isStandalone) {
    return (
      <div className="donation-details-standalone">
        {content}
      </div>
    );
  }

  // If modal, wrap in overlay
  return (
    <div className="popup-overlay" onClick={onClose}>
      {content}
    </div>
  );
};

// Card Components
const ItemCard = ({ item, quote }) => {
  return (
    <div className={`item-card item-card--${item.status.toLowerCase().replace(' ', '-')}`}>
      <div className="item-card__header">
        <div className="item-card__title-group">
          {getStatusIcon(item.status)}
          <div className="item-card__text">
            <h3 className="item-card__title">{item.description}</h3>
            <div className="item-card__status-container">
              <span className={`item-card__status-badge item-card__status-badge--${item.status.toLowerCase().replace(' ', '-')}`}>
                {STATUS_LABELS[item.status.toLowerCase()] || item.status}
              </span>
              <p className="item-card__sku">SKU: {item.sku}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="item-card__details">
        <div className="item-card__detail">
          <FaBox className="item-card__detail-icon" />
          <span className="item-card__detail-text">
            Quantity: {item.quantity} {item.unitOfMeasure}
          </span>
        </div>
        <div className="item-card__detail">
          <FaDollarSign className="item-card__detail-icon" />
          <span className="item-card__detail-text">
            Est. Value: {formatCurrency(item.estimatedValue)}
          </span>
        </div>
        {quote && (
          <div className="item-card__detail">
            <FaFileInvoiceDollar className="item-card__detail-icon" />
            <span className="item-card__detail-text">
              Quote Amount: {formatCurrency(quote.amount)}
            </span>
          </div>
        )}
      </div>

      {/* Only show View Quote Details button if status is not pending */}
      {item.status.toLowerCase() !== 'pending' && (
        <div className="card__actions">
          <Link 
            to={`/quotes/${quote?.id}`}
            className="card__action-button"
          >
            <FaClipboardList className="card__action-icon" />
            View Quote Details
            <FaChevronRight className="card__action-arrow" />
          </Link>
        </div>
      )}
    </div>
  );
};

const LogisticsTaskCard = ({ task }) => {
  const [requestId, setRequestId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [charityName, setCharityName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch both request ID and charity details in parallel
        const [id, charity] = await Promise.all([
          approvalRequestService.getRequestIdByTaskId(task.id),
          charityService.getById(task.charityId)
        ]);
        
        setRequestId(id);
        setCharityName(charity?.orgName || 'Unassigned');
      } catch (error) {
        console.error('Error fetching data:', error);
        setCharityName('Error loading charity');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [task.id, task.charityId]);

  return (
    <div className={`logistics-card logistics-card--${task.status.toLowerCase()}`}>
      <div className="logistics-card__header">
        <div className="logistics-card__title-group">
          {getStatusIcon(task.status)}
          <div className="logistics-card__text">
            <h3 className="logistics-card__title">
              {loading ? 'Loading...' : charityName}
            </h3>
            <span className={`logistics-card__status-badge logistics-card__status-badge--${task.status.toLowerCase()}`}>
              {STATUS_LABELS[task.status] || task.status}
            </span>
          </div>
        </div>
      </div>
      
      <div className="logistics-card__details">
        <div className="logistics-card__detail">
          <FaPallet className="logistics-card__detail-icon" />
          <span className="logistics-card__detail-text">
            Group {task.palletGroup} • {task.palletQuantity} pallets
          </span>
        </div>
        {task.pickupDateTime && (
          <div className="logistics-card__detail">
            <FaCalendarCheck className="logistics-card__detail-icon" />
            <span className="logistics-card__detail-text">
              Pickup: {formatDate(task.pickupDateTime)}
            </span>
          </div>
        )}
      </div>

      <div className="card__actions">
        {!loading && requestId ? (
          <Link 
            to={`/manage-pickups/${requestId}`}
            className="card__action-button"
          >
            <FaTruck className="card__action-icon" />
            View Pickup Details
            <FaChevronRight className="card__action-arrow" />
          </Link>
        ) : loading ? (
          <div className="card__action-button card__action-button--disabled">
            <FaClock className="card__action-icon" />
            Loading...
          </div>
        ) : (
          <div className="card__action-button card__action-button--disabled">
            <FaExclamationCircle className="card__action-icon" />
            Request Not Found
          </div>
        )}
      </div>
    </div>
  );
};

const DonationCard = ({ donation, quotes, logisticsTasks }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const handleViewDetails = () => {
    if (window.location.pathname === '/my-impact-dashboard') {
      setShowPopup(true); // Show popup when in dashboard
    } else {
      navigate(`/my-impact-dashboard/${donation.id}`); // Navigate when accessing directly
    }
  };

  return (
    <div className="donation-group">
      <div className={`donation-card donation-card--${donation.status.toLowerCase()}`}>
        <div className="donation-card__header">
          <div className="donation-card__title-group">
            {getStatusIcon(donation.status)}
            <div className="donation-card__text">
              <h3 className="donation-card__title">
                Donation #{donation.id}
              </h3>
              <span className={`donation-card__status-badge donation-card__status-badge--${donation.status.toLowerCase()}`}>
                {STATUS_LABELS[donation.status.toLowerCase()] || donation.status}
              </span>
            </div>
          </div>
          <button 
            onClick={handleViewDetails}
            className="card__action-button"
          >
            <FaInfoCircle className="card__action-icon" />
            View Details
            <FaChevronRight className="card__action-arrow" />
          </button>
        </div>

        <div className="donation-card__details">
          <div className="donation-card__detail">
            <FaWarehouse className="donation-card__detail-icon" />
            <span className="donation-card__detail-text">
              {donation.distributionCenterName}
            </span>
          </div>
          <div className="donation-card__detail">
            <FaBox className="donation-card__detail-icon" />
            <span className="donation-card__detail-text">
              {donation.items.length} items
            </span>
          </div>
          <div className="donation-card__detail">
            <FaDollarSign className="donation-card__detail-icon" />
            <span className="donation-card__detail-text">
              Est. Value: {formatCurrency(donation.totalEstimatedValue)}
            </span>
          </div>
        </div>
      </div>

      <div className="donation-children">
        {donation.items.map((item) => (
          <div key={item.itemID} className="item-group">
            <ItemCard 
              item={item} 
              quote={quotes.find(q => q.itemId === item.itemID)}
            />
            <div className="item-logistics">
              {logisticsTasks
                .filter(task => task.itemId === item.itemID)
                .sort((a, b) => a.palletGroup - b.palletGroup)
                .map((task) => (
                  <LogisticsTaskCard key={task.id} task={task} />
                ))
              }
            </div>
          </div>
        ))}
      </div>

      {showPopup && (
        <DonationDetailsPopup
          donation={donation}
          onClose={() => setShowPopup(false)}
          isStandalone={false}
        />
      )}
    </div>
  );
};

// Main Component
const DonationTracker = () => {
  const [donations, setDonations] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [logisticsTasks, setLogisticsTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Get user and check role
        const currentUser = authService.getUserSession();
        if (!currentUser) throw new Error('No authenticated user found');
        
        const userData = await userService.getById(currentUser.id);
        if (!userData) throw new Error('User data not found');
        
        // Get distribution centers based on user role
        let dcs = [];
        if (userData.role === 'SystemAdmin') {
          // SystemAdmin sees all distribution centers
          dcs = await distributionCenterService.getAll();
        } else {
          // Regular users only see their assigned DCs
          const userDCs = userData.distributionCenters || [userData.distributionCenter];
          dcs = await Promise.all(
            userDCs.map(dcId => distributionCenterService.getById(dcId))
          );
        }

        // Fetch data for all distribution centers
        const allData = await Promise.all(dcs.map(async (dc) => {
          const [dcDonations, dcQuotes, dcLogisticsTasks] = await Promise.all([
            donationService.getByDistributionCenter(dc.id),
            quoteService.fetchAllQuotes(dc.id),
            logisticsService.getTasks({ distributionCenter: dc.id })
          ]);

          return {
            donations: dcDonations.map(donation => ({
              ...donation,
              distributionCenterName: dc.name
            })),
            quotes: dcQuotes,
            logisticsTasks: dcLogisticsTasks
          };
        }));

        // Combine all data
        const allDonations = allData.flatMap(d => d.donations);
        const allQuotes = allData.flatMap(d => d.quotes);
        const allLogisticsTasks = allData.flatMap(d => d.logisticsTasks);

        // Filter and process pending donations
        const pendingDonations = allDonations
          .filter(donation => donation.status.toLowerCase() === 'pending')
          .map(donation => {
            const items = donation.items.map(item => {
              const itemQuote = allQuotes.find(q => q.itemId === item.itemID);
              
              let status = 'pending';
              if (itemQuote) {
                switch (itemQuote.status.toLowerCase()) {
                  case 'pending':
                    status = 'quote sent';
                    break;
                  case 'approved':
                    status = 'quote approved';
                    break;
                  case 'rejected':
                    status = 'quote rejected';
                    break;
                  case 'completed':
                    status = 'completed';
                    break;
                }
              }

              const itemTasks = allLogisticsTasks.filter(t => t.itemId === item.itemID);
              if (itemTasks.length > 0 && itemTasks.every(t => t.status === 'completed')) {
                status = 'completed';
              }

              return {
                ...item,
                status
              };
            });

            return {
              ...donation,
              items
            };
          });

        // Sort donations by creation date (newest first)
        const sortedDonations = pendingDonations.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });

        setDonations(sortedDonations);
        setQuotes(allQuotes);
        setLogisticsTasks(allLogisticsTasks);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching donation data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorState message={error} />;
  if (donations.length === 0) return <EmptyState />;

  return (
    <div className="donation-tracker">
      <div className="donation-tracker__header">
        <h2 className="donation-tracker__title">Pending Donations</h2>
        <p className="donation-tracker__subtitle">
          Tracking {donations.length} active donation{donations.length !== 1 ? 's' : ''}
        </p>
      </div>

      <div className="donation-tracker__timeline">
        {donations.map((donation) => (
          <DonationCard
            key={donation.id}
            donation={donation}
            quotes={quotes}
            logisticsTasks={logisticsTasks}
          />
        ))}
      </div>
    </div>
  );
};

export default DonationTracker;