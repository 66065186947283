import React from 'react';
import { FaSpinner, FaHandHoldingHeart, FaCheck, FaTable } from 'react-icons/fa';
import '../styles/CsvPreview.css';

const CsvPreview = ({ csvData, isLoading, onSubmit }) => {
  if (!csvData || csvData.length === 0) return null;

  return (
    <div className="mt-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <FaTable className="mr-2" />
          Preview ({csvData.length} {csvData.length === 1 ? 'charity' : 'charities'})
        </h3>
        <button
          onClick={onSubmit}
          disabled={isLoading}
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? (
            <>
              <FaSpinner className="animate-spin" />
              Processing...
            </>
          ) : (
            <>
              <FaHandHoldingHeart />
              Submit Charities
            </>
          )}
        </button>
      </div>

      <div className="overflow-x-auto bg-white rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Organization
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                EIN
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Contact
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Capacity
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {csvData.map((charity, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {charity.orgName}
                  </div>
                  {charity.orgWebsite && (
                    <div className="text-sm text-gray-500">{charity.orgWebsite}</div>
                  )}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{charity.ein}</div>
                </td>
                <td className="px-4 py-3">
                  <div className="text-sm text-gray-900">
                    {charity.locations[0].address.line1}
                  </div>
                  <div className="text-sm text-gray-500">
                    {`${charity.locations[0].address.city}, ${charity.locations[0].address.stateProvince} ${charity.locations[0].address.zipCode}`}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="text-sm text-gray-900">
                    {`${charity.locations[0].contacts[0].firstName} ${charity.locations[0].contacts[0].lastName}`}
                  </div>
                  <div className="text-sm text-gray-500">
                    {charity.locations[0].contacts[0].email}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {`${charity.locations[0].palletCapacity.minimum} - ${charity.locations[0].palletCapacity.maximum} pallets`}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CsvPreview;