import React, { useReducer, useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FaSearch, FaCalendarAlt, FaClock, FaCheckCircle, FaTimesCircle, FaEye, FaSort, FaQuestion, 
         FaBuilding, FaBoxes, FaPallet, FaFileInvoice, FaHandHoldingHeart, FaMapMarkerAlt, FaCity, 
         FaGlobeAmericas, FaExclamationTriangle, FaSpinner, FaClipboardList, FaListUl, FaCalendarWeek } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { logisticsService } from '../services/logisticsService';
import { approvalRequestService } from '../services/approvalRequestService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { distributionCenterService } from '../services/distributionCenterService';
import { messageService } from '../services/messageService';
import '../styles/ApprovalRequests.css';

const localizer = momentLocalizer(moment);

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

const initialState = {
  approvalRequests: [],
  user: null,
  loading: true,
  error: null,
  filters: {
    status: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  },
  sort: {
    field: 'pickupDateTime',
    direction: 'desc',
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
  },
  distributionCenters: [],
  selectedDC: 'all',
  initialLoadComplete: false,
};

const statusOptions = [
  { value: 'pending', icon: FaClock, color: '#FFA500', label: 'Pending' },
  { value: 'approved', icon: FaCheckCircle, color: '#28A745', label: 'Approved' },
  { value: 'rejected', icon: FaTimesCircle, color: '#DC3545', label: 'Rejected' },
];

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false, initialLoadComplete: true };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false, initialLoadComplete: true };
    case 'SET_FILTER':
      return { ...state, filters: { ...state.filters, [action.payload.name]: action.payload.value } };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters, selectedDC: 'all' };
    case 'SET_SORT':
      return { ...state, sort: action.payload };
    case 'SET_PAGINATION':
      return { ...state, pagination: { ...state.pagination, ...action.payload } };
    case 'SET_SELECTED_DC':
      return { ...state, selectedDC: action.payload };
    default:
      return state;
  }
}

const TypeIndicator = ({ type }) => {
  const icon = type === 'pickupDateApproval' ? <FaCalendarAlt /> : <FaQuestion />;
  return (
    <div className="type-indicator">
      {icon}
      <span>{type}</span>
    </div>
  );
};

const CustomCalendarEvent = ({ event }) => (
  <div className="custom-event">
    <div className="event-title">
      {event.itemDescription} | {formatNumber(event.palletQuantity)} pallets
    </div>
    <div className="event-time">
      {moment(event.start).format('HH:mm')}
    </div>
    <div className="event-status">
      {event.status === 'pending' && <FaClock />}
      {event.status === 'approved' && <FaCheckCircle />}
      {event.status === 'rejected' && <FaTimesCircle />}
    </div>
  </div>
);

const CalendarView = React.memo(({ events, onSelectEvent }) => {
  const eventStyleGetter = (event) => ({
    style: {
      backgroundColor: statusOptions.find(option => option.value === event.status)?.color,
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    }
  });

  const { defaultDate, scrollToTime } = useMemo(() => ({
    defaultDate: new Date(),
    scrollToTime: new Date(new Date().setHours(6, 0, 0, 0))
  }), []);

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={onSelectEvent}
        components={{
          event: CustomCalendarEvent
        }}
        defaultView='month'
        defaultDate={defaultDate}
        scrollToTime={scrollToTime}
        views={['month', 'week', 'day']}
      />
    </div>
  );
});

const FilterBar = React.memo(({ filters, dispatch, statusOptions, distributionCenters, selectedDC }) => (
  <div className="filters">
    <div className="filter-group">
      <FaSearch className="icon" />
      <input 
        type="text" 
        placeholder="Search by request ID" 
        value={filters.searchTerm}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'searchTerm', value: e.target.value } })}
      />
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <select 
        value={filters.status} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'status', value: e.target.value } })}
      >
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <input 
        type="date" 
        value={filters.dateRange.start || ''} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, start: e.target.value } } })}
      />
      <span>to</span>
      <input 
        type="date" 
        value={filters.dateRange.end || ''} 
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, end: e.target.value } } })}
      />
    </div>
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select 
        value={selectedDC} 
        onChange={(e) => dispatch({ type: 'SET_SELECTED_DC', payload: e.target.value })}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <button className="btn btn-reset" onClick={() => dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</button>
  </div>
));

const StatusIndicator = ({ status }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <div 
      className={`status-indicator status-${status}`}
      data-tooltip-id="tooltip" 
      data-tooltip-content={statusOption.label}
    >
      <Icon className="status-icon" />
      <span>{statusOption.label}</span>
    </div>
  );
};

const ApprovalRequestsTable = React.memo(({ 
  requests, 
  onApprove, 
  onReject, 
  expandedRequest, 
  onExpand,
  sort, 
  onSort 
}) => {
  const renderSortIcon = (field) => {
    if (sort.field !== field) return <FaSort />;
    return sort.direction === 'asc' ? <FaSort className="asc" /> : <FaSort className="desc" />;
  };

  return (
    <div className="approval-requests-table-container">
      <table className="approval-requests-table">
        <thead>
          <tr>
            <th onClick={() => onSort('id')}>Request ID {renderSortIcon('id')}</th>
            <th onClick={() => onSort('type')}>Type {renderSortIcon('type')}</th>
            <th onClick={() => onSort('pickupDateTime')}>Pickup Date/Time {renderSortIcon('pickupDateTime')}</th>
            <th onClick={() => onSort('status')}>Status {renderSortIcon('status')}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.map(request => (
            <React.Fragment key={request.id}>
              <tr className={expandedRequest?.id === request.id ? 'expanded' : ''}>
                <td data-label="Request ID">{request.id}</td>
                <td data-label="Type"><TypeIndicator type={request.type} /></td>
                <td data-label="Pickup Date/Time">{moment(request.pickupDateTime).format('MMMM D, YYYY HH:mm')}</td>
                <td data-label="Status">
                  <StatusIndicator status={request.status} />
                </td>
                <td data-label="Actions">
                  <div className="action-buttons">
                    {request.status === 'pending' && (
                      <>
                        <button className="btn btn-approve" onClick={() => onApprove(request)}>
                          <FaCheckCircle /> Approve
                        </button>
                        <button className="btn btn-reject" onClick={() => onReject(request)}>
                          <FaTimesCircle /> Reject
                        </button>
                      </>
                    )}
                    <button className="btn btn-view" onClick={() => onExpand(request.id)}>
                      <FaEye /> {expandedRequest?.id === request.id ? 'Hide' : 'View'}
                    </button>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
});

const ExpandedRequestDetails = React.forwardRef(({ request, onClose, onApprove, onReject }, ref) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const task = await logisticsService.getTaskById(request.taskId);
        setTaskDetails(task);
        if (task.bolPdfUrl) {
          setPdfUrl(task.bolPdfUrl);
        } else {
          setPdfError("No PDF URL available for this task");
        }
      } catch (error) {
        console.error('Error fetching task details:', error);
        setPdfError(`Failed to load task details: ${error.message}`);
      }
    };

    fetchTaskDetails();
  }, [request.taskId]);

  if (!taskDetails) {
    return <div className="expanded-request-details loading">Loading task details...</div>;
  }

  return (
    <div ref={ref} className="expanded-request-details">
      <div className="details-header">
        <h3>Request Details</h3>
        <button className="btn btn-close" onClick={onClose}><FaTimesCircle /></button>
      </div>
      <div className="task-details-grid">
        <div className="task-info">
          <h3>Pickup Request</h3>
            
          {/* Pickup Date/Time Section */}
          <div className="info-group datetime-info">
            <div className="datetime-grid">
              <div className="datetime-item">
                <strong>
                  <FaCalendarAlt className="datetime-icon" />
                  Pickup Date
                </strong>
                <div className="datetime-content">
                  {moment(taskDetails.pickupDateTime).format('dddd, MMMM D, YYYY')}
                </div>
              </div>
              <div className="datetime-item">
                <strong>
                  <FaClock className="datetime-icon" />
                  Pickup Time
                </strong>
                <div className="datetime-content">
                  {moment(taskDetails.pickupDateTime).format('h:mm A')}
                </div>
              </div>
            </div>
          </div>
            
          {/* Action Buttons - Moved here */}
          {request.status === 'pending' && (
            <div className="info-group">
              <div className="action-buttons">
                <button className="btn btn-approve" onClick={() => onApprove(request)}>
                  <FaCheckCircle /> Approve
                </button>
                <button className="btn btn-reject" onClick={() => onReject(request)}>
                  <FaTimesCircle /> Reject
                </button>
              </div>
            </div>
          )}
            
          {/* Primary Pickup Information */}
          <div className="info-group primary-info">
            <h4>Pickup Details</h4>
            <p><FaBuilding /> <strong>Distribution Center:</strong> <span>{taskDetails.distributionCenterName}</span></p>
            <p className="address-line"><FaMapMarkerAlt /> <strong>Address:</strong> <span>{taskDetails.distributionCenterAddress.street}</span></p>
            <p className="address-line"><FaCity /> <strong>City/State:</strong> <span>{taskDetails.distributionCenterAddress.city}, {taskDetails.distributionCenterAddress.state} {taskDetails.distributionCenterAddress.zip}</span></p>
            <p><FaPallet /> <strong>Pallet Quantity:</strong> <span>{formatNumber(taskDetails.palletQuantity)}</span></p>
            <p><FaBoxes /> <strong>Item Description:</strong> <span>{taskDetails.itemDescription}</span></p>
          </div>

          {/* Charity Information */}
          <div className="info-group charity-info">
            <h4>Charity Information</h4>
            <p><FaHandHoldingHeart /> <strong>Charity Name:</strong> <span>{taskDetails.charityName}</span></p>
            <p><FaMapMarkerAlt /> <strong>Delivery Location:</strong> <span>{taskDetails.charityLocationName}</span></p>
            <p className="address-line"><FaMapMarkerAlt /> <strong>Address:</strong> <span>{taskDetails.charityAddress.street} {taskDetails.charityAddress.street2}</span></p>
            <p className="address-line"><FaCity /> <strong>City/State:</strong> <span>{taskDetails.charityAddress.city}, {taskDetails.charityAddress.state} {taskDetails.charityAddress.zip}</span></p>
            <p><FaCalendarAlt /> <strong>Delivery Date:</strong> <span>{moment(taskDetails.deliveryDate).format('MMMM D, YYYY')}</span></p>
          </div>

          {/* Reference Numbers */}
          <div className="info-group reference-info">
            <h4>Reference Information</h4>
            <p><FaFileInvoice /> <strong>BOL ID:</strong> <span>{taskDetails.id}</span></p>
            <p><FaFileInvoice /> <strong>Quote ID:</strong> <span>{taskDetails.quoteId}</span></p>
            <p><FaHandHoldingHeart /> <strong>Donation ID:</strong> <span>{taskDetails.donationId}</span></p>
            <p><FaClipboardList /> <strong>Task ID:</strong> <span>{taskDetails.id}</span></p>
          </div>
        </div>
        <div className="bol-preview">
          <h3>Bill of Lading</h3>
          <div className="pdf-container">
            {pdfUrl ? (
              <iframe src={pdfUrl} width="100%" height="100%" title="Bill of Lading" />
            ) : pdfError ? (
              <div className="error-message"><FaExclamationTriangle /> {pdfError}</div>
            ) : (
              <div className="loading-message"><FaSpinner className="fa-spin" /> Loading Bill of Lading...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="pagination">
    <button 
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="btn"
    >
      Previous
    </button>
    <span className="pagination-info">
      Page {currentPage} of {totalPages}
    </span>
    <button 
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="btn"
    >
      Next
    </button>
  </div>
);

const ApprovalRequests = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [expandedRequest, setExpandedRequest] = useState(null);
  const [viewMode, setViewMode] = useState('calendar');
  const expandedDetailsRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');
  
      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');
  
      if (!userData.distributionCenters || userData.distributionCenters.length === 0) {
        throw new Error('User has no assigned distribution centers');
      }
  
      const userDCs = userData.distributionCenters || [userData.distributionCenter];
      const dcs = await Promise.all(userDCs.map(dcId => 
        distributionCenterService.getById(dcId)
      ));
  
      const requests = await Promise.all(dcs.map(dc => 
        approvalRequestService.getRequestsByDistributionCenter(dc.id)
      ));
  
      const flattenedRequests = requests.flat();
  
      const requestsWithTaskDetails = await Promise.all(flattenedRequests.map(async (request) => {
        const taskDetails = await logisticsService.getTaskById(request.taskId);
        return { ...request, taskDetails };
      }));
  
      dispatch({ 
        type: 'SET_DATA', 
        payload: { 
          user: userData, 
          approvalRequests: requestsWithTaskDetails,
          distributionCenters: dcs,
        } 
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to load approval requests. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (state.approvalRequests.length > 0 && requestId) {
      const request = state.approvalRequests.find(r => r.id === requestId);
      if (request) {
        setExpandedRequest(request);
        setTimeout(() => {
          expandedDetailsRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      } else {
        toast.error('Request not found');
        navigate('/manage-pickups');
      }
    }
  }, [requestId, state.approvalRequests, navigate]);

  const handleApprove = async (request) => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser?.id) {
        throw new Error('No authenticated user found');
      }
  
      await approvalRequestService.updateRequest(request.id, { 
        status: 'approved',
        approvedBy: currentUser.id,
        approvedAt: new Date().toISOString()
      });
      await logisticsService.updateTask(request.taskId, { status: 'scheduled' });
      
      const task = await logisticsService.getTaskById(request.taskId);
      
      if (task?.requestedByUserId && task?.distributionCenterId) {
        try {
          const [requestingAdmin, approver, distributionCenter] = await Promise.all([
            userService.getById(task.requestedByUserId),
            userService.getById(currentUser.id),
            distributionCenterService.getById(task.distributionCenterId)
          ]);
  
          if (requestingAdmin?.phone) {
            await messageService.sendPickupRequestDecisionNotification(
              requestingAdmin.phone,
              {
                status: 'approved',
                approverName: approver?.name || 'System Administrator',
                itemDescription: task.itemDescription,
                distributionCenterName: distributionCenter?.name || 'Unknown Distribution Center',
                charityName: task.charityName,
                palletQuantity: task.palletQuantity,
                pickupDateTime: task.pickupDateTime,
                deliveryDate: task.deliveryDate
              }
            );
          }
        } catch (notificationError) {
          console.error('Error sending notification:', notificationError);
        }
      }
  
      setExpandedRequest(null);
      navigate('/manage-pickups');
      await fetchData();
      toast.success('Request approved successfully');
    } catch (error) {
      console.error('Error approving request:', error);
      toast.error('Failed to approve request. Please try again.');
    }
  };
  
  const handleReject = async (request) => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser?.id) {
        throw new Error('No authenticated user found');
      }
  
      await approvalRequestService.updateRequest(request.id, { 
        status: 'rejected',
        rejectedBy: currentUser.id,
        rejectedAt: new Date().toISOString()
      });
      await logisticsService.updateTask(request.taskId, { status: 'rejected' });
      
      const task = await logisticsService.getTaskById(request.taskId);
      
      if (task?.requestedByUserId && task?.distributionCenterId) {
        try {
          const [requestingAdmin, approver, distributionCenter] = await Promise.all([
            userService.getById(task.requestedByUserId),
            userService.getById(currentUser.id),
            distributionCenterService.getById(task.distributionCenterId)
          ]);
  
          if (requestingAdmin?.phone) {
            await messageService.sendPickupRequestDecisionNotification(
              requestingAdmin.phone,
              {
                status: 'rejected',
                approverName: approver?.name || 'System Administrator',
                itemDescription: task.itemDescription,
                distributionCenterName: distributionCenter?.name || 'Unknown Distribution Center',
                charityName: task.charityName,
                palletQuantity: task.palletQuantity,
                pickupDateTime: task.pickupDateTime,
                deliveryDate: task.deliveryDate
              }
            );
          }
        } catch (notificationError) {
          console.error('Error sending notification:', notificationError);
        }
      }
  
      setExpandedRequest(null);
      navigate('/manage-pickups');
      await fetchData();
      toast.success('Request rejected successfully');
    } catch (error) {
      console.error('Error rejecting request:', error);
      toast.error('Failed to reject request. Please try again.');
    }
  };

  const handleExpand = useCallback((requestId) => {
    const isCurrentlyExpanded = expandedRequest?.id === requestId;
    if (isCurrentlyExpanded) {
      setExpandedRequest(null);
      navigate('/manage-pickups');
    } else {
      navigate(`/manage-pickups/${requestId}`);
    }
  }, [expandedRequest, navigate]);

  const handleCalendarEventSelect = (event) => {
    navigate(`/manage-pickups/${event.request.id}`);
  };

  const handleCloseDetails = useCallback(() => {
    setExpandedRequest(null);
    navigate('/manage-pickups');
  }, [navigate]);

  const filteredAndSortedRequests = useMemo(() => {
    return state.approvalRequests
      .filter(request => {
        const searchLower = state.filters.searchTerm.toLowerCase();
        const matchesSearch = request.id.toLowerCase().includes(searchLower);
        const matchesStatus = state.filters.status === 'all' || request.status === state.filters.status;
        const matchesDateRange = 
          (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
          (new Date(request.pickupDateTime) >= new Date(state.filters.dateRange.start) &&
           new Date(request.pickupDateTime) <= new Date(state.filters.dateRange.end));
        const matchesDC = state.selectedDC === 'all' || request.distributionCenterId === state.selectedDC;

        return matchesSearch && matchesStatus && matchesDateRange && matchesDC;
      })
      .sort((a, b) => {
        if (a[state.sort.field] < b[state.sort.field]) return state.sort.direction === 'asc' ? -1 : 1;
        if (a[state.sort.field] > b[state.sort.field]) return state.sort.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [state.approvalRequests, state.filters, state.sort, state.selectedDC]);

  const paginatedRequests = useMemo(() => {
    const startIndex = (state.pagination.currentPage - 1) * state.pagination.itemsPerPage;
    return filteredAndSortedRequests.slice(startIndex, startIndex + state.pagination.itemsPerPage);
  }, [filteredAndSortedRequests, state.pagination]);

  const calendarEvents = useMemo(() => {
    return filteredAndSortedRequests.map(request => ({
      id: request.id,
      title: `${request.taskDetails?.itemDescription || 'No description'} | ${request.taskDetails?.palletQuantity || 0} pallets`,
      start: new Date(request.pickupDateTime),
      end: new Date(request.pickupDateTime),
      status: request.status,
      itemDescription: request.taskDetails?.itemDescription || 'No description',
      palletQuantity: request.taskDetails?.palletQuantity || 0,
      request: request
    }));
  }, [filteredAndSortedRequests]);

  const totalPages = Math.ceil(filteredAndSortedRequests.length / state.pagination.itemsPerPage);

  if (state.loading && !state.initialLoadComplete) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="approval-requests-container">
          <div className="approval-requests-content">
            <h1><FaClipboardList /> Manage Pickup Requests</h1>
            
            {(!requestId || expandedRequest) && (
              <>
                <FilterBar 
                  filters={state.filters}
                  dispatch={dispatch}
                  statusOptions={statusOptions}
                  distributionCenters={state.distributionCenters}
                  selectedDC={state.selectedDC}
                />
                <div className="view-toggle">
                  <button 
                    className={`btn ${viewMode === 'table' ? 'active' : ''}`} 
                    onClick={() => setViewMode('table')}
                  >
                    <FaListUl /> Table View
                  </button>
                  <button 
                    className={`btn ${viewMode === 'calendar' ? 'active' : ''}`} 
                    onClick={() => setViewMode('calendar')}
                  >
                    <FaCalendarWeek /> Calendar View
                  </button>
                </div>

                {viewMode === 'calendar' ? (
                  <CalendarView 
                    events={calendarEvents} 
                    onSelectEvent={handleCalendarEventSelect} 
                  />
                ) : (
                  <>
                    <ApprovalRequestsTable 
                      requests={paginatedRequests}
                      onApprove={handleApprove}
                      onReject={handleReject}
                      expandedRequest={expandedRequest}
                      onExpand={handleExpand}
                      sort={state.sort}
                      onSort={(field) => dispatch({ type: 'SET_SORT', payload: { 
                        field, 
                        direction: state.sort.field === field && state.sort.direction === 'asc' ? 'desc' : 'asc' 
                      }})}
                    />
                    <Pagination
                      currentPage={state.pagination.currentPage}
                      totalPages={totalPages}
                      onPageChange={(page) => dispatch({ type: 'SET_PAGINATION', payload: { currentPage: page } })}
                    />
                  </>
                )}
              </>
            )}

            {expandedRequest && (
              <ExpandedRequestDetails 
                ref={expandedDetailsRef}
                request={expandedRequest} 
                onClose={handleCloseDetails}
                onApprove={handleApprove}
                onReject={handleReject}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};

export default ApprovalRequests;