import React, { useState, useEffect } from 'react';
import { 
  FaChevronLeft,
  FaChevronRight, 
  FaDollarSign,
  FaUsers,
  FaRecycle,
  FaHeart,
  FaArrowRight
} from 'react-icons/fa';

// Constants for screen breakpoints
const BREAKPOINTS = {
  MOBILE: 640,
  TABLET: 768,
  DESKTOP: 1024
};

// Slide data configuration
const METRICS_DATA = [
  {
    icon: FaDollarSign,
    value: "$2.1M",
    label: "Value of Donations",
    bgColor: "bg-blue-50",
    textColor: "text-blue-600"
  },
  {
    icon: FaUsers,
    value: "3,875",
    label: "People Helped",
    bgColor: "bg-purple-50",
    textColor: "text-purple-600"
  },
  {
    icon: FaRecycle,
    value: "28.8k lbs",
    label: "Saved from Landfill",
    bgColor: "bg-teal-50",
    textColor: "text-teal-600"
  },
  {
    icon: FaDollarSign,
    value: "$267K",
    label: "Estimated Tax Savings",
    bgColor: "bg-green-50",
    textColor: "text-green-600"
  },
];

const STRATEGY_CARDS = [
  {
    title: "Align Mission",
    description: "Ensure receiving charity partners align with goop's mission to minimize waste and maintain consistent values across partnerships.",
    icon: FaHeart,
    color: "text-purple-600"
  },
  {
    title: "Keep it Simple",
    description: "Maximum 3 partner strategy enables smooth deliveries, enhanced predictability, and accelerated community impact.",
    icon: FaRecycle,
    color: "text-purple-600"
  },
  {
    title: "Maximize Impact",
    description: "'Spreading the love' strategically across communities ensures broader reach and optimized benefit distribution.",
    icon: FaUsers,
    color: "text-purple-600"
  }
];

const CHARITY_DATA = [
  {
    name: "Los Angeles Regional Food Bank",
    tag: "Fire Relief Focus",
    tagColor: "blue",
    description: "Leading the charge in LA County's fire relief efforts, providing essential resources and support to affected communities with a special focus on women's services.",
    area: "All LA County",
    pallets: "Up to 26 pallets"
  },
  {
    name: "Altadena Girls",
    tag: "Youth Empowerment",
    tagColor: "pink",
    description: "Empowering young women by providing the goods and community needed to find a sense of normalcy after disaster. A fire relief nonprofit founded by teenage girls in Altadena.",
    area: "Altadena",
    pallets: "2-4 pallets",
    priority: true,
  },
  {
    name: "Clyde Community Relief Center",
    tag: "Community Support",
    tagColor: "green",
    description: "A cornerstone of local community support, providing essential resources and services to families in need. Specialized in immediate relief and sustainable assistance programs.",
    area: "Local Community",
    pallets: "1-5 pallets",
    priority: true
  },
  {
    name: "The Midnight Mission",
    tag: "Homeless Services",
    tagColor: "indigo",
    description: "Dedicated to providing comprehensive support for homeless individuals and families, offering shelter, meals, and rehabilitation programs to transform lives.",
    area: "Greater LA",
    pallets: "3-5 pallets"
  },
  {
    name: "This is About Humanity",
    tag: "Human Rights",
    tagColor: "orange",
    description: "Supporting vulnerable populations through advocacy and direct assistance, focusing on human rights and dignity for all community members. Partnering with NDLON for on-site distribution.",
    area: "Pasadena",
    pallets: "3-6 pallets"
  },
];

// Reusable components
const Card = ({ children, className = "", highlighted = false }) => (
  <div className={`bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 ${
    highlighted ? 'border-2 border-purple-200' : ''
  } ${className}`}>
    {children}
  </div>
);

const MetricCard = ({ icon: Icon, value, label, bgColor, textColor }) => (
  <Card className="p-4 sm:p-6">
    <div className={`w-12 h-12 ${bgColor} rounded-lg flex items-center justify-center mb-4`}>
      <Icon className={`w-6 h-6 ${textColor}`} />
    </div>
    <div className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-2">{value}</div>
    <div className="text-xs sm:text-sm text-gray-500 uppercase tracking-wider">{label}</div>
  </Card>
);

const getTagColors = (color) => {
    switch (color) {
      case 'blue':
        return 'bg-blue-100 text-blue-800';
      case 'pink':
        return 'bg-pink-100 text-pink-800';
      case 'green':
        return 'bg-green-100 text-green-800';
      case 'indigo':
        return 'bg-indigo-100 text-indigo-800';
      case 'orange':
        return 'bg-orange-100 text-orange-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

const CharityCard = ({ charity }) => (
    <Card className="p-6 relative">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 mb-4">
        <h3 className="text-xl font-bold text-gray-800">{charity.name}</h3>
        <span className={`${getTagColors(charity.tagColor)} px-4 py-1.5 rounded-full text-sm font-medium whitespace-nowrap`}>
          {charity.tag}
        </span>
      </div>
    <p className="text-sm sm:text-base text-gray-600 mb-6 leading-relaxed">
      {charity.description}
    </p>
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 pt-4 border-t border-gray-100">
      <span className="text-sm text-gray-500">Service Area: {charity.area}</span>
      <span className="bg-gray-100 px-3 py-1.5 rounded-full text-sm font-medium">
        {charity.pallets}
      </span>
    </div>
    {charity.priority && (
      <div className="absolute -top-2 -right-2 bg-yellow-400 text-yellow-900 px-3 py-1 rounded-lg text-sm font-bold shadow-md">
        Priority Partner
      </div>
    )}
  </Card>
);

const NavigationButton = ({ direction, onClick, disabled }) => {
  const Icon = direction === 'next' ? FaChevronRight : FaChevronLeft;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="p-2 sm:p-3 bg-gray-800/90 text-white rounded-full hover:bg-gray-700 transition-colors duration-200 
                disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
      aria-label={direction === 'next' ? 'Next slide' : 'Previous slide'}
    >
      <Icon className="w-4 h-4 sm:w-5 sm:h-5" />
    </button>
  );
};

// Main component
const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= BREAKPOINTS.MOBILE;
  const isTablet = windowWidth <= BREAKPOINTS.TABLET;

  const handleKeyNavigation = (e) => {
    if (e.key === 'ArrowRight' && currentSlide < slides.length - 1) {
      setCurrentSlide(prev => prev + 1);
    } else if (e.key === 'ArrowLeft' && currentSlide > 0) {
      setCurrentSlide(prev => prev - 1);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyNavigation);
    return () => window.removeEventListener('keydown', handleKeyNavigation);
  }, [currentSlide]);

  const slides = [
    // Cover Slide
    <div key="cover" className="relative h-full flex flex-col justify-center items-center">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900">
        <div className="absolute inset-0 opacity-10">
          <div className="w-full h-full" style={{ 
            backgroundImage: 'radial-gradient(circle at 1px 1px, white 1px, transparent 0)',
            backgroundSize: '40px 40px' 
          }}></div>
        </div>
      </div>

      <div className="relative z-10 w-full h-full flex flex-col justify-center items-center text-white p-6">
        <div className="flex flex-col sm:flex-row items-center gap-6 mb-12">
          <div className="w-24 sm:w-32 h-24 sm:h-32">
            <img 
              src="https://goop-img.com/wp-content/uploads/2023/10/headshot-gooplogo-2.png"
              alt="goop logo"
              className="w-full h-full object-contain"
            />
          </div>
          
          <div className="hidden sm:block h-20 w-px bg-purple-400/30"></div>
          
          <div className="w-48 sm:w-80">
            <img 
              src="https://static.wixstatic.com/media/0e94a2_9bdce83bfa77459ca3b7e65fb5f5d720~mv2.png/v1/fill/w_250,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Donating%20Simplified%20White%20Wordmark.png"
              alt="Donating Simplified logo"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
        
        <div className="flex flex-col items-center">
        <h1 className="text-4xl sm:text-6xl lg:text-7xl font-bold tracking-tight text-center mb-6">
            Donation Strategy
        </h1>
        <div className="w-24 h-1 bg-purple-400 rounded-full mb-6"></div>
        <h2 className="text-2xl sm:text-4xl lg:text-5xl font-light tracking-wide text-purple-200 text-center">
            Mission LA | Powered by Donating Simplified
        </h2>
        </div>

        <div className="absolute bottom-8 sm:bottom-12 left-0 right-0 flex justify-center">
          <div className="text-purple-300/50 text-base sm:text-xl tracking-[0.5em] uppercase">
            2025
          </div>
        </div>
      </div>
    </div>,

    // Strategy Slide
    <div key="strategy" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
          Custom Donation Strategy
        </h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {STRATEGY_CARDS.map((strategy, index) => (
            <Card key={index} className="p-6">
              <strategy.icon className={`w-8 h-8 ${strategy.color} mb-6`} />
              <h3 className="text-xl font-bold mb-4 text-gray-800">{strategy.title}</h3>
              <p className="text-gray-600 leading-relaxed">{strategy.description}</p>
            </Card>
          ))}
        </div>

        <Card className="mt-8 sm:mt-12 p-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
            <FaRecycle className="w-10 h-10 text-purple-600 flex-shrink-0" />
            <div>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">
                End-to-End Expert Donation Management
              </h3>
              <p className="text-gray-600 leading-relaxed">
              Donating Simplified maximizes efficiency through rapid product placement and delivery, ensures complete process visibility throughout donations, and creates impact by partnering with custom-selected LA charities that optimize community benefit.
              </p>
            </div>
          </div>
        </Card>
      </div>
    </div>,

    // Impact Dashboard Slide
    <div key="impact" className="h-full overflow-y-auto bg-white p-6 sm:p-12">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-8 sm:mb-12">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800">
            Projected Impact Dashboard
          </h2>
          <div className="bg-red-500/80 text-white px-4 py-1.5 sm:py-2 text-lg font-bold 
                        tracking-wider rounded-md shadow-md whitespace-nowrap">
            PENDING
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {METRICS_DATA.map((metric, index) => (
            <MetricCard key={index} {...metric} />
          ))}
        </div>

        <Card className="mt-8 sm:mt-12 p-6 bg-gradient-to-r from-orange-50 to-red-50 border border-orange-100">
          <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">LA Wildfire Relief Impact</h3>
          <p className="text-gray-700 leading-relaxed sm:text-lg">
            In the wake of devastating wildfires across Los Angeles County, goop's strategic donation 
            will provide essential resources to communities rebuilding their lives. Working with our 
            trusted partners, tactically chosen to quickly maximize impact, these donations will directly 
            support emergency relief efforts, helping displaced families access vital supplies and resources. 
            By focusing on women's support services and community outreach, we're ensuring that vulnerable 
            populations receive the immediate assistance they need during this critical time of recovery.
          </p>
        </Card>
      </div>
    </div>,

    // Options Comparison Slide
    <div key="options" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
          Donation Options
        </h2>
        
        <div className="space-y-6 sm:space-y-8">
          <Card className="p-6">
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">Option 1: Maximimum Efficiency - Smooth & Simple!</h3>
            <div className="pl-4 sm:pl-6 border-l-4 border-blue-500">
              <p className="text-lg sm:text-xl mb-4 text-blue-700 font-medium">Los Angeles Regional Food Bank</p>
              <ul className="space-y-3">
                {[
                  "All 15 pallets to single location",
                  "Fastest single site distribution",
                  "Supports LA fire relief efforts",
                  "Focus on women's support services",
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <div className="w-2 h-2 bg-blue-500 rounded-full mr-3 flex-shrink-0"></div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Card>

          <Card highlighted className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
              <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">Option 2: Strategic Charity Distribution </h3>
              <span className="bg-purple-100 text-purple-800 px-4 py-1.5 rounded-full text-sm sm:text-base font-medium whitespace-nowrap">
                Recommended
              </span>
            </div>
            <div className="pl-4 sm:pl-6 border-l-4 border-purple-500">
              <p className="text-lg sm:text-xl mb-4 text-purple-700 font-medium">Spreading the Community Love</p>
              <ul className="space-y-3">
                {[
                  "Most visibility and direct impact",
                    "This is About Humanity (6 pallets)",
                    "Clyde Community Relief Center (5 pallets)",
                    "Altadena Girls (4 pallets)",
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <div className="w-2 h-2 bg-purple-500 rounded-full mr-3 flex-shrink-0"></div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Card>

          <Card className="p-6">
            <h3 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">
              Option 3: Maximum Reach - 5 Locations
            </h3>
            <div className="pl-4 sm:pl-6 border-l-4 border-green-500">
              <p className="text-lg sm:text-xl mb-4 text-green-700 font-medium">Maximum Impact Distribution</p>
              <ul className="space-y-3">
                {[
                  "Los Angeles Regional Food Bank (4 pallets)",
                    "Altadena Girls (3 pallets)",
                    "The Midnight Mission (3 pallets)",
                    "This is About Humanity (3 pallet)",
                    "Clyde Community Relief Center (2 pallets)",
                ].map((item, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <div className="w-2 h-2 bg-green-500 rounded-full mr-3 flex-shrink-0"></div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        </div>

        <div className="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-xl shadow-md">
          <p className="text-yellow-800 text-sm sm:text-base">
            <span className="font-bold">Special Note:</span> Altadena Girls has specially confirmed availability for Donating Simplified. Clyde Relief is hosting an impact event on Saturday Feb 1 for 400 Altadena victims.
          </p>
        </div>
      </div>
    </div>,

    // Charity Profiles Slide
    <div key="charities" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
          Charity Partner Profiles
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
          {CHARITY_DATA.map((charity, index) => (
            <CharityCard key={index} charity={charity} />
          ))}
        </div>
      </div>
    </div>
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4 sm:py-12">
      {/* Presentation Container */}
      <div className={`relative w-full max-w-7xl mx-auto ${
        isMobile ? 'h-[85vh]' : 'aspect-video'
      } bg-white shadow-2xl rounded-2xl overflow-hidden`}>
        {/* Slides */}
        <div className="absolute inset-0">
          {slides[currentSlide]}
        </div>
        
        {/* Navigation Controls */}
        <div className="absolute bottom-4 sm:bottom-8 right-4 sm:right-8 flex gap-2 sm:gap-4 z-20">
          <NavigationButton
            direction="prev"
            onClick={prevSlide}
            disabled={currentSlide === 0}
          />
          <NavigationButton
            direction="next"
            onClick={nextSlide}
            disabled={currentSlide === slides.length - 1}
          />
        </div>
        
        {/* Slide Counter */}
        <div className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 bg-gray-800/90 text-white 
                      px-4 sm:px-6 py-1.5 sm:py-2 rounded-full text-sm sm:text-base font-medium z-20">
          {currentSlide + 1} / {slides.length}
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full max-w-7xl mx-auto mt-4 sm:mt-8 px-4">
        <div className="bg-gray-700 rounded-full h-1">
          <div 
            className="bg-purple-500 h-1 rounded-full transition-all duration-500 ease-in-out"
            style={{ width: `${((currentSlide + 1) / slides.length) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;