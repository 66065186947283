import React, { useState, useEffect } from 'react';
import { FaGlobe, FaExclamationTriangle } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import CharityExplorer from './Charities/CharityExplorer';
import '../styles/ExploreCharities.css';

const ExploreCharities = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeComponent = async () => {
      setIsLoading(true);
      try {
        const currentUser = await authService.getCurrentUser();
        const userData = authService.getUserSession();

        if (!currentUser) {
          throw new Error('No authenticated user found');
        }

        if (userData?.role !== 'SystemAdmin') {
          throw new Error('Unauthorized access. System Admin role required.');
        }

        const fullUserData = await userService.getById(userData.id);
        if (!fullUserData) {
          throw new Error('User data not found');
        }

        setUser(fullUserData);
      } catch (error) {
        console.error('Error initializing component:', error);
        setError(error.message);
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initializeComponent();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorMessage message={error} />;

  if (!user || user.role !== 'SystemAdmin') {
    return (
      <div className="unauthorized-message">
        <FaExclamationTriangle />
        <h2>Unauthorized Access</h2>
        <p>You must be a System Admin to view this page.</p>
      </div>
    );
  }

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="explore-charities-container">
          <div className="explore-charities-content">
            <h1>
              <FaGlobe /> Explore Partner Charities
            </h1>
            
            <CharityExplorer />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default ExploreCharities;