// CharityMap.js
import React, { useEffect, useRef } from 'react';
import { 
  FaMapMarkerAlt,
  FaBuilding
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import { mapService } from '../../services/mapService';
import '../../styles/Charities/CharityMap.css';

const CharityMap = ({ charities, onSelectCharity }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    const initMap = async () => {
      try {
        // Initialize map
        const mapInstance = await mapService.createMap('charity-map', {
          CharityIcon: FaBuilding,
          DistributionCenterIcon: FaMapMarkerAlt
        });

        mapInstanceRef.current = mapInstance;

        // Format locations for the map
        const formattedLocations = charities.flatMap(charity => 
          charity.locations.map(location => ({
            id: charity.id,
            name: charity.orgName,
            address: {
              street: `${location.address.line1}${location.address.line2 ? ` ${location.address.line2}` : ''}`,
              city: location.address.city,
              state: location.address.stateProvince,
              zip: location.address.zipCode,
              latitude: location.coordinates?.latitude,
              longitude: location.coordinates?.longitude
            },
            stats: {
              totalDonationsReceived: location.palletCapacity.maximum,
              totalFairMarketValue: 0, // Add if available
              palletsReceived: location.palletCapacity.maximum
            }
          }))
        );

        // Update markers on the map
        mapInstance.updateMarkers([], formattedLocations); // Empty array for distribution centers

      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    if (!mapInstanceRef.current) {
      initMap();
    }

    return () => {
      // Cleanup if needed
      mapInstanceRef.current = null;
    };
  }, [charities]);

  // Update markers when charities change
  useEffect(() => {
    if (mapInstanceRef.current) {
      const formattedLocations = charities.flatMap(charity => 
        charity.locations.map(location => ({
          id: charity.id,
          name: charity.orgName,
          address: {
            street: `${location.address.line1}${location.address.line2 ? ` ${location.address.line2}` : ''}`,
            city: location.address.city,
            state: location.address.stateProvince,
            zip: location.address.zipCode,
            latitude: location.coordinates?.latitude,
            longitude: location.coordinates?.longitude
          },
          stats: {
            totalDonationsReceived: location.palletCapacity.maximum,
            totalFairMarketValue: 0, // Add if available
            palletsReceived: location.palletCapacity.maximum
          }
        }))
      );

      mapInstanceRef.current.updateMarkers([], formattedLocations);
    }
  }, [charities]);

  return (
    <div className="charity-map-container">
      <div 
        id="charity-map" 
        ref={mapRef} 
        className="charity-map"
        role="application"
        aria-label="Map showing charity locations"
      />
    </div>
  );
};

CharityMap.propTypes = {
  charities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({
      coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
      }),
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string,
        city: PropTypes.string.isRequired,
        stateProvince: PropTypes.string.isRequired,
        zipCode: PropTypes.string.isRequired
      }).isRequired,
      palletCapacity: PropTypes.shape({
        maximum: PropTypes.number.isRequired
      }).isRequired
    })).isRequired
  })).isRequired,
  onSelectCharity: PropTypes.func.isRequired
};

export default React.memo(CharityMap);